import React, { createContext, FC, ReactNode, useEffect, useState } from "react";

import { SettingsTwoTone } from "@mui/icons-material";
import { Box, Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet";

export interface MaintenanceMode {
    inMaintenance: boolean
}

export interface SettingsContextValue {
    mode: MaintenanceMode;
}

interface SettingsProviderProps {
    children?: ReactNode;
}

const initialMode: MaintenanceMode = {
    inMaintenance: false
};

export const restoreSettings = (): MaintenanceMode | null => {
    let mode = null;

    try {
        const storedData: string | null = window.localStorage.getItem('mode');

        if (storedData) {
            mode = JSON.parse(storedData);
        } else {
            mode = initialMode;
        }
    } catch (err) {
        console.error(err);
    }

    return mode;
};

export const storeMode = (settings: MaintenanceMode): void => {
    window.localStorage.setItem('mode', JSON.stringify(settings));
};

export const MaintenanceContext = createContext<SettingsContextValue>({
    mode: initialMode
});

export const MaintenanceModeProvider: FC<SettingsProviderProps> = (props) => {
    const { children } = props;
    const [mode, setMode] = useState<MaintenanceMode>(initialMode);

    useEffect(() => {
        const restoredSettings = restoreSettings();

        if (restoredSettings) {
            setMode(restoredSettings);
        }
    }, []);

    const saveMode = (updatedSettings: MaintenanceMode): void => {
        setMode(updatedSettings);
        storeMode(updatedSettings);
    };

    useEffect(() => {
        if ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
            console.log("Development environment.")
            return;
        }

        if (!process.env.REACT_APP_BASE_API_URL) {
            console.log("Missing or invalid API URL.")
            return
        }

        const addressForWS = process.env.REACT_APP_BASE_API_URL.replace("http", "ws")

        let webSocket: WebSocket
        function connectWebSocket() {
            const ws = new WebSocket(`${addressForWS}`);
            ws.onmessage = (message) => {
                const updatedState = JSON.parse(message.data)
                saveMode(updatedState)
            }

            ws.onclose = () => {
                if(ws.readyState != ws.OPEN) {
                    setTimeout(connectWebSocket, 5000)
                }
            }

            ws.onerror = () => {
                ws.close()
            }

            webSocket = ws
        }
        connectWebSocket()

        return () => {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            webSocket.onclose = () => { }
            webSocket.close()
        }
    }, [])

    return (
        <MaintenanceContext.Provider
            value={{
                mode
            }}
        >
            {mode.inMaintenance ? <MaintenancePage /> : children}
        </MaintenanceContext.Provider>
    )
};

export const MaintenancePage: FC = () => {
    return (
        <>
            <Helmet>
                <title>Maintenance Mode | Pegaxy Apollo</title>
            </Helmet>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 7,
                }}
            >
                <Container maxWidth={'lg'} sx={{ display: 'flex', justifyContent: "center", flexDirection: { xs: 'column', md: 'row' } }}>
                    <Box sx={{ mt: 14, width: { xs: '100%', md: 'calc(100% - 321px)' }, pr: { xs: 0, md: 4 }, textAlign: "center" }}>
                        <SettingsTwoTone
                            fontSize="large"
                            sx={{
                                mb: 2,
                                fontSize: "64px",
                                animation: "spin 1.5s linear infinite",
                                "@keyframes spin": {
                                    "0%": {
                                        transform: "rotate(0deg)",
                                    },
                                    "100%": {
                                        transform: "rotate(360deg)",
                                    },
                                },
                            }} />
                        <Typography variant="h4" sx={{ mb: 3, fontWeight: "900" }}>
                            Hang on! We are under maintenance
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            Apollo platform is currently under maintenance and will be back shortly.
                        </Typography>
                        <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
                            Thank you for your patience.
                        </Typography>
                    </Box>
                </Container>
            </Box>
        </>
    )
}
