import {alpha, Avatar, Box, Button, Card, Tooltip, Typography} from "@mui/material";
import {Loading} from "../../../utils/Loading";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {TetherLogo} from "../../../layout/icons/tether-icon";
import React, {FC, ReactElement} from "react";
import {useTheme} from "@mui/material/styles";

interface PegaStats {
    speed: number;
    strength: number;
    water: number;
    wind: number;
    fire: number;
    lightning: number;
}

interface PegaFloorPriceData {
    bloodLine: string;
    breedType?: string;
    breedCount?: number;
    gender?: string;
    pegaIds: Array<number>;
    listingIds: Array<number>;
    price: number;
    raceable: number;
    breedable: number;
    stats: PegaStats;
}

interface PegaFloorPriceCardProps {
    loading: boolean;
    floorPriceData?: PegaFloorPriceData;
    bloodLineTitle: string;
    bloodLineIcon: ReactElement
    iconBackgroundColor: string;
    iconBackgroundAlpha: number;
}

export const PegaFloorPriceCard: FC<PegaFloorPriceCardProps> = ({
    loading,
    floorPriceData,
    bloodLineTitle,
    bloodLineIcon,
    iconBackgroundColor,
    iconBackgroundAlpha
}) => {
    const theme = useTheme()

    return (
        <>
            <Card sx={{p: 2, pb: 1, boxShadow: 10}}>
                <Box
                    sx={{
                        justifyContent: "space-between",
                        alignItems: 'center',
                        display: 'flex',
                        mb: 3
                    }}
                >
                    <>
                        <Avatar
                            variant="rounded"
                            sx={{
                                backgroundColor: (theme) => alpha(iconBackgroundColor, iconBackgroundAlpha),
                                color: 'primary.main',
                                p: 1
                            }}
                        >
                            { bloodLineIcon }
                        </Avatar>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            {
                                loading ? (
                                    <Box>
                                        <Loading/>
                                    </Box>
                                ) : (
                                    <Button
                                        disabled={!floorPriceData || (!!floorPriceData && floorPriceData.pegaIds?.length === 0)}
                                        component={floorPriceData ? "a" : "button"}
                                        href={floorPriceData && floorPriceData.pegaIds?.length > 0 ? `https://marketplace.pegaxy.io/pega/${floorPriceData.pegaIds[0]}` : '#'}
                                        target={"_blank"}
                                        rel={"noreferrer"}
                                        endIcon={!floorPriceData || (!!floorPriceData && floorPriceData.pegaIds?.length === 0) ? false : <OpenInNewIcon sx={{ color: theme.palette.text.primary }} />}
                                    >
                                        <Box
                                            sx={{width: 25, height: 25, mr: 1}}
                                        >
                                            <TetherLogo/>
                                        </Box>
                                        <Typography variant="h5" color={theme.palette.text.primary}>
                                            {floorPriceData ? floorPriceData.price?.toLocaleString() : '--'}
                                        </Typography>
                                    </Button>
                                )
                            }
                        </Box>
                    </>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                    <Typography
                        color="textSecondary"
                        variant="overline"
                        sx={{ mx: 1 }}
                    >
                        {`${bloodLineTitle}`}
                    </Typography>
                    {
                        loading || !floorPriceData ? null : (
                            <>
                                <Typography
                                    color="textSecondary"
                                    variant="overline"
                                >
                                    {`•`}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="overline"
                                    sx={{ mx: 1 }}
                                >
                                    {`${floorPriceData?.breedType}`}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="overline"
                                >
                                    {`•`}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="overline"
                                    sx={{ mx: 1 }}
                                >
                                    {`${floorPriceData?.gender}`}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="overline"
                                >
                                    {`•`}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="overline"
                                    sx={{ mx: 1 }}
                                >
                                    {`${floorPriceData?.breedCount}/7`}
                                </Typography>
                                {/*<Typography*/}
                                {/*    color="textSecondary"*/}
                                {/*    variant="overline"*/}
                                {/*>*/}
                                {/*    {`${floorPriceData?.bloodLine} • ${floorPriceData?.breedType} • ${floorPriceData?.gender} • ${floorPriceData?.breedCount}/7`}*/}
                                {/*</Typography>*/}
                            </>
                        )
                    }
                </Box>
            </Card>
            <Card sx={{p: 1, pb: 1, mt: 1, boxShadow: 10}}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            m: .5
                        }}
                    >
                        <Tooltip title={"Speed"} placement={"top"}>
                            <Avatar
                                variant="circular"
                                sx={{
                                    backgroundColor: (theme) => alpha(theme.palette.mode === "dark" ? theme.palette.neutral[100] : theme.palette.neutral[900], 0.05),
                                    color: 'primary.main',
                                    p: 1,
                                    mx: 'auto',
                                    my: 0,
                                    width: 30,
                                    height: 30
                                }}
                            >
                                <i className="fas fa-pegasus"
                                   style={{ color: 'rgb(255, 143, 6)', fontSize: 11 }}
                                />
                            </Avatar>
                        </Tooltip>
                        <Typography
                            variant={"overline"}
                        >
                            { floorPriceData?.stats.speed.toFixed(0) }
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            m: .5
                        }}
                    >
                        <Tooltip title={"Strength"} placement={"top"}>
                            <Avatar
                                variant="circular"
                                sx={{
                                    backgroundColor: (theme) => alpha(theme.palette.mode === "dark" ? theme.palette.neutral[100] : theme.palette.neutral[900], 0.05),
                                    color: 'primary.main',
                                    p: 1,
                                    mx: 'auto',
                                    my: 0,
                                    width: 30,
                                    height: 30
                                }}
                            >
                                <i className="fas fa-hand-rock"
                                   style={{ color: 'rgb(61, 255, 129)', fontSize: 11 }}
                                />
                            </Avatar>
                        </Tooltip>
                        <Typography
                            variant={"overline"}
                        >
                            { floorPriceData?.stats.strength.toFixed(0) }
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            m: .5
                        }}
                    >
                        <Tooltip title={"Lightning"} placement={"top"}>
                            <Avatar
                                variant="circular"
                                sx={{
                                    backgroundColor: (theme) => alpha(theme.palette.mode === "dark" ? theme.palette.neutral[100] : theme.palette.neutral[900], 0.05),
                                    color: 'primary.main',
                                    p: 1,
                                    mx: 'auto',
                                    my: 0,
                                    width: 30,
                                    height: 30
                                }}
                            >
                                <i className="fas fa-bolt"
                                   style={{ color: 'rgb(255, 218, 28)', fontSize: 11 }}
                                />
                            </Avatar>
                        </Tooltip>
                        <Typography
                            variant={"overline"}
                        >
                            { floorPriceData?.stats.lightning.toFixed(0) }
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            m: .5,
                        }}
                    >
                        <Tooltip title={"Wind"} placement={"top"}>
                            <Avatar
                                variant="circular"
                                sx={{
                                    backgroundColor: (theme) => alpha(theme.palette.mode === "dark" ? theme.palette.neutral[100] : theme.palette.neutral[900], 0.05),
                                    color: 'primary.main',
                                    p: 1,
                                    mx: 'auto',
                                    my: 0,
                                    width: 30,
                                    height: 30
                                }}
                            >
                                <i className="fas fa-wind"
                                   style={{ color: 'rgb(255, 255, 255)', fontSize: 11 }}
                                />
                            </Avatar>
                        </Tooltip>
                        <Typography
                            variant={"overline"}
                        >
                            { floorPriceData?.stats.wind.toFixed(0) }
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            m: .5
                        }}
                    >
                        <Tooltip title={"Water"} placement={"top"}>
                            <Avatar
                                variant="circular"
                                sx={{
                                    backgroundColor: (theme) => alpha(theme.palette.mode === "dark" ? theme.palette.neutral[100] : theme.palette.neutral[900], 0.05),
                                    color: 'primary.main',
                                    p: 1,
                                    mx: 'auto',
                                    my: 0,
                                    width: 30,
                                    height: 30
                                }}
                            >
                                <i className="fas fa-water"
                                   style={{ color: 'rgb(47, 188, 237)', fontSize: 11 }}
                                />
                            </Avatar>
                        </Tooltip>
                        <Typography
                            variant={"overline"}
                        >
                            { floorPriceData?.stats.water.toFixed(0) }
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            m: .5
                        }}
                    >
                        <Tooltip title={"Fire"} placement={"top"}>
                            <Avatar
                                variant="circular"
                                sx={{
                                    backgroundColor: (theme) => alpha(theme.palette.mode === "dark" ? theme.palette.neutral[100] : theme.palette.neutral[900], 0.05),
                                    color: 'primary.main',
                                    p: 1,
                                    mx: 'auto',
                                    my: 0,
                                    width: 30,
                                    height: 30
                                }}
                            >
                                <i className="fas fa-fire"
                                   style={{ color: 'rgb(255, 31, 0)', fontSize: 11 }}
                                />
                            </Avatar>
                        </Tooltip>
                        <Typography
                            variant={"overline"}
                        >
                            { floorPriceData?.stats.fire.toFixed(0) }
                        </Typography>
                    </Box>
                </Box>
            </Card>
        </>
    )
}
