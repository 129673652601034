import create from 'zustand';

import { getCurrentUser } from '../../api/auth';
import { IAuthUser } from '../../interfaces/user';
import { processErrorMessage } from '../../utils/processErrorMessage';

interface AuthState {
  user: IAuthUser | null;
  storedToken: string | null;
  loading: boolean;
  error?: any;

  getUser: (accessToken: string) => Promise<void>;
  clearUser: () => void;
}

export const useAuthStore = create<AuthState>(
  ((set) => ({
    user: null,
    storedToken: window.localStorage.getItem('accessToken') || null,
    loading: false,
    error: null,

    getUser: async (accessToken) => {
      set({ loading: true });

      try {
        window.localStorage.setItem('accessToken', accessToken);
        const user = await getCurrentUser(accessToken);
        set({ user, storedToken: accessToken });
      } catch (err) {
        set({ error: processErrorMessage(err) });
      }

      set({ loading: false });
    },

    clearUser: () => {
      window.localStorage.removeItem('accessToken');
      set({ user: null, storedToken: null });
    },
  })),
);
