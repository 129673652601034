export const ApolloLogo = () => {
        return (
            <svg style={{ width: '100%', maxWidth: '100%' }} id="Layer_13" data-name="Layer 13" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1605.01 195.41">
                    <path fill="#FFFFFF" d="M86.27,12l72.14,169.25H140L86.27,52.77,32,181.25H13.6Z"/>
                    <path fill="#FFFFFF"
                          d="M319.84,19.57H371a85.7,85.7,0,0,1,15.41,1.35,48.71,48.71,0,0,1,13,4.16A50.84,50.84,0,0,1,417.77,41.9Q425.29,53,425.28,69a57.63,57.63,0,0,1-2.43,17.14,52.76,52.76,0,0,1-6.54,14,47.61,47.61,0,0,1-9.41,10.44A38.58,38.58,0,0,1,395.65,117a49.28,49.28,0,0,1-11.08,2.32q-6,.6-14.44.6H336.71v61.32H319.84Zm50.5,86c4.33,0,7.81-.08,10.44-.27a32.48,32.48,0,0,0,8.38-1.78,20.77,20.77,0,0,0,7.46-4.38,31.71,31.71,0,0,0,6.12-7.52A40.62,40.62,0,0,0,407,81.48a44.88,44.88,0,0,0,1.57-12.06q0-12-4.65-19.63A31.11,31.11,0,0,0,392.3,38.38Q384.52,34,371,34H336.71v71.59Z"/>
                    <path fill="#FFFFFF"
                          d="M664.47,32a61.25,61.25,0,0,0-20.65,5.41,65.74,65.74,0,0,0-21.09,15A69.64,69.64,0,0,0,609,74.4,72.1,72.1,0,0,0,609,127a68.61,68.61,0,0,0,13.74,21.69,65.3,65.3,0,0,0,21,14.81,63.67,63.67,0,0,0,20.76,5.3V183.2a84.91,84.91,0,0,1-26.39-6.06,81.17,81.17,0,0,1-26.55-17.47,83.12,83.12,0,0,1-17.84-26.28,81.32,81.32,0,0,1-6.54-32.71,80,80,0,0,1,6.65-32.5,84.27,84.27,0,0,1,44.5-44.4,81,81,0,0,1,26.17-6.27ZM673,169a67.6,67.6,0,0,0,24.87-5.3,62.68,62.68,0,0,0,21-14.6,65.57,65.57,0,0,0,13.52-21.68,73.48,73.48,0,0,0,4.76-26.56,76.7,76.7,0,0,0-4.76-27.3A65.92,65.92,0,0,0,719,51.69a61.63,61.63,0,0,0-20.87-14.6A68.57,68.57,0,0,0,673,31.79V17.29a86.31,86.31,0,0,1,30.71,6.28,80.16,80.16,0,0,1,44,44A84.49,84.49,0,0,1,754,100.35a84.31,84.31,0,0,1-6.43,33.15A82.27,82.27,0,0,1,730,159.89a78.84,78.84,0,0,1-26.34,17.36,87.73,87.73,0,0,1-30.6,6.16Z"/>
                    <path fill="#FFFFFF" d="M922.26,19.57h16.87v147.3h72.68v14.38H922.26Z"/>
                    <path fill="#FFFFFF" d="M1175.62,19.57h16.87v147.3h72.68v14.38h-89.55Z"/>
                    <path fill="#FFFFFF"
                          d="M1501.87,32a61.24,61.24,0,0,0-20.66,5.41,65.74,65.74,0,0,0-21.09,15,69.44,69.44,0,0,0-13.73,22,72,72,0,0,0,0,52.61,68.4,68.4,0,0,0,13.73,21.69,65.3,65.3,0,0,0,21,14.81,63.73,63.73,0,0,0,20.77,5.3V183.2a84.91,84.91,0,0,1-26.39-6.06,81,81,0,0,1-26.55-17.47,83,83,0,0,1-17.85-26.28,81.32,81.32,0,0,1-6.54-32.71,80.21,80.21,0,0,1,6.65-32.5,85.88,85.88,0,0,1,18-26.5,84.94,84.94,0,0,1,26.55-17.9,81,81,0,0,1,26.18-6.27Zm8.54,137a67.61,67.61,0,0,0,24.88-5.3,62.78,62.78,0,0,0,21-14.6,65.55,65.55,0,0,0,13.51-21.68,73.27,73.27,0,0,0,4.76-26.56,76.48,76.48,0,0,0-4.76-27.3,65.92,65.92,0,0,0-13.41-21.9,61.53,61.53,0,0,0-20.87-14.6,68.53,68.53,0,0,0-25.09-5.3V17.29a86.37,86.37,0,0,1,30.72,6.28,80.16,80.16,0,0,1,44,44,84.48,84.48,0,0,1,6.32,32.82A84.13,84.13,0,0,1,1585,133.5a82.27,82.27,0,0,1-17.63,26.39A78.9,78.9,0,0,1,1541,177.25a87.78,87.78,0,0,1-30.61,6.16Z"/>
            </svg>
        )
}
