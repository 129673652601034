import axios from 'axios';
import {useEffect, useState} from "react";
import BigNumber from 'bignumber.js';
import { sortBy } from "lodash";
import useTokenLiveData from './useTokenLiveData';

export interface PoolLiquidityProvider {
    publicAddress: string;
    liquidityTokenBalance: string;
    poolShare: string;
    visBalance: string;
    usdtBalance: string;
    usdValue: string;
}

interface LiquidityPosition {
    user: {
        id: string
    },
    liquidityTokenBalance: string
}

interface LiquidityProvidersAPIResponse {
    data: {
        pool: {
            totalSupply: string
            reserve0: string
            reserve1: string
        }
        liquidityPositions: Array<LiquidityPosition>
    }
}

export default function useVISPoolLiquidityProviders (pool: string, visPrice: number) {
    const baseURL = `https://api.thegraph.com/subgraphs/name/dynamic-amm/dmm-exchange-matic`;

    const gqlStringQuery = `{\n  pool(id: "${pool}") {\n    totalSupply\n    reserve0\n    reserve1\n  }\n  \n  liquidityPositions(first: 1000, where: {\n    pool: "${pool}",\n    liquidityTokenBalance_gt: 0\n  }) {\n    user {\n      id\n    }\n    liquidityTokenBalance\n  }\n\n}`;

    const [totalProviders, setTotalProviders] = useState<number>();
    const [totalUsdt, setTotalUsdt] = useState<string>();
    const [totalVis, setTotalVis] = useState<string>();
    const [totalUsdValue, setTotalUsdValue] = useState<string>();

    const [liquidityPositions, setLiquidityPositions] = useState<Array<PoolLiquidityProvider>>([]);

    const [loading, setLoading] = useState<boolean>(true);

    const getLPData = async () => {
        setLoading(true);

        try {
            const { data }: { data: LiquidityProvidersAPIResponse } = await axios.post(`${baseURL}`, {
                query: gqlStringQuery
            })

            const totalSupply = data.data.pool.totalSupply;
            const totalUsdt = data.data.pool.reserve0;
            const totalVis = data.data.pool.reserve1;

            setTotalProviders(data.data.liquidityPositions.length);
            setTotalUsdt(new BigNumber(totalUsdt).toFormat(2));
            setTotalVis(new BigNumber(totalVis).toFormat(2));

            setTotalUsdValue(
                new BigNumber(totalVis).multipliedBy(visPrice).plus(totalUsdt).toFormat(2)
            )

            const sortedLPArray = sortBy(data.data.liquidityPositions, ['liquidityTokenBalance']).reverse();

            const liqPositions = sortedLPArray.slice(0, 50).map((lpItem: LiquidityPosition) => {
                const liquidityBalance = new BigNumber(lpItem.liquidityTokenBalance);
                const totalLPSupply = new BigNumber(totalSupply);
                const usdtPoolReserve = new BigNumber(totalUsdt);
                const visPoolReserve = new BigNumber(totalVis);


                const visTokenAmount = liquidityBalance.dividedBy(totalLPSupply).multipliedBy(visPoolReserve);
                const usdtTokenAmount = liquidityBalance.dividedBy(totalLPSupply).multipliedBy(usdtPoolReserve);

                const poolShare = liquidityBalance.multipliedBy(100).dividedBy(totalSupply);

                const usdValue = visTokenAmount.multipliedBy(visPrice).plus(usdtTokenAmount)

                return {
                    publicAddress: lpItem.user.id,
                    liquidityTokenBalance: lpItem.liquidityTokenBalance,
                    visBalance: visTokenAmount.toFormat(2),
                    usdtBalance: usdtTokenAmount.toFormat(2),
                    poolShare: poolShare.toFormat(2),
                    usdValue: usdValue.toFormat(2)
                };
            })

            setLiquidityPositions(liqPositions);

            setLoading(false);
        } catch (err) {
            console.log('error getting lp data', err);
        }
    }

    useEffect(() => {
        if (pool && visPrice) {
            getLPData()
        }
    }, [pool, visPrice])

    return {
        loading,
        totalProviders,
        totalUsdt,
        totalVis,
        totalUsdValue,
        liquidityPositions
    };
}
