import { FC, useEffect, useState } from "react";
import { Box, Card, CardHeader } from "@mui/material";
import useBurnedPegaData from "./useBurnedPegaData";
import { Loading } from "../../utils/Loading";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { useTheme } from "@mui/material/styles";
import { Scrollbar } from "../../layout/scrollbar/Scrollbar";

const getChartOptions = (theme: any, labels: Array<string>, dataset: Array<any>): ApexOptions => {
  return {
    chart: {
      background: 'transparent',
      type: 'bar',
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: false
      },
      offsetX: -15,
    },
    colors: ['#1f87e6', '#ff5c7c'],
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '100%',
        dataLabels: {
          hideOverflowingLabels: true
        }
      },
    },
    stroke: {
      colors: ['transparent'],
      show: true,
      width: 1
    },
    xaxis: {
      type: 'category',
      categories: ["Pacer", "Rare", "Epic", "Legendary", "Founding"],
      labels: {
        show: true
      },
      tickAmount: 10
    },
    yaxis: {
      title: {
        text: undefined
      },
      labels: {
        show: true,
        style: {
          colors: theme.palette.text.secondary
        },
      }
    },
    tooltip: {
      x: {
        show: false,
        formatter: (val: number, opts?: any): string => {
          return val + '%'
        }
      }
    },
    fill: {
      opacity: 1
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: -10,
      offsetY: 15,
      labels: {
        colors: theme.palette.text.secondary
      }
    },
    theme: {
      mode: theme.palette.mode,
      monochrome: {
        enabled: true,
        color: theme.palette.info.dark,
        shadeTo: 'dark',
        shadeIntensity: 0.75
      }
    },
    grid: {
      show: true,
      borderColor: theme.palette.text.secondary,
      xaxis: {
        lines: {
          show: true
        }
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: undefined,
      formatter: function (val, opts) {
        const value = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex]
        return `${value.toLocaleString()}`
      },
    },
  }
}

const PegaPopulationBurnedChart: FC = () => {
  const {
    populationData,
    burnedPacer,
    burnedRare,
    burnedEpic,
    burnedLegendary,
    burnedFounding,
    loadingBurnedBreakdown,
    loadingPopulationData
  } = useBurnedPegaData();
  const theme = useTheme();

  const [chartData, setChartData] = useState<any | null>(null)
  const [chartOptions, setChartOptions] = useState<ApexOptions>({});

  useEffect(() => {
    if (!populationData || burnedEpic == null || burnedRare == null || burnedPacer == null) {
      return
    }

    const data = [
      {
        "name": "Dead",
        "data": [
          burnedPacer, burnedRare, burnedEpic, burnedLegendary, burnedFounding
        ],
        "color": "#ff5c7c"
      },
      {
        "name": "Alive",
        "data": [
          populationData.pacer - burnedPacer, populationData.rare - burnedRare,
          populationData.epic - burnedEpic, populationData.legendary - burnedLegendary,
          populationData.founding - burnedFounding
        ],
        "color": "#1f87e6"
      },
    ]
    const labels = ["Pacer", "Rare", "Epic", "Legendary", "Founding"];
    const chartOptions = getChartOptions(theme, labels, data)
    setChartData(data);
    setChartOptions(chartOptions);
  }, [populationData, burnedPacer, burnedRare, burnedEpic, burnedLegendary, burnedFounding, theme])

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        p: 2,
        pb: 1
      }}
    >
      <Card sx={{ boxShadow: 10 }}>
        <CardHeader
          sx={{ pb: 0 }}
          title={"Pega Population"}
        />
        <Scrollbar>
          <Box
            sx={{
              px: 2,
              py: 1,
              maxHeight: 225,
              minWidth: 700,
              overflowY: 'hidden'
            }}
          >
            {
              loadingBurnedBreakdown || loadingPopulationData ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mb: 10
                  }}
                >
                  <Loading />
                </Box>
              ) : (
                <Chart
                  height={200}
                  options={chartOptions}
                  series={chartData}
                  type="bar"
                />
              )
            }
          </Box>
        </Scrollbar>
      </Card>
    </Box>
  );
};

export default PegaPopulationBurnedChart;
