import React, {FC} from "react";
import {useTheme} from "@mui/material/styles";
import {Box, Button, Card, Typography} from "@mui/material";
import {Loading} from "../../utils/Loading";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {TetherLogo} from "../../layout/icons/tether-icon";

enum TicketRarity {
    Legendary = "Legendary",
    Epic = "Epic",
    Rare = "Rare"
}

export interface ValentineTicketFloorPriceData {
    price: number;
    ticketIds?: Array<number>;
    ticketRarity: TicketRarity;
}

interface ValentineTicketFloorPriceCardProps {
    loading: boolean;
    floorPriceData?: ValentineTicketFloorPriceData;
    ticketTitle: string;
    ticketMarketplaceLink: string;
    ticketBackgroundColor: string;
}

export const ValentineTicketFloorPriceCard: FC<ValentineTicketFloorPriceCardProps> = ({
    loading,
    floorPriceData,
    ticketTitle,
    ticketMarketplaceLink,
    ticketBackgroundColor
}) => {
    const theme = useTheme();

    return (
        <Card sx={{p: 3, pb: 1, boxShadow: 10}}>
            <Box
                sx={{
                    justifyContent: "space-between",
                    alignItems: 'center',
                    display: 'flex',
                    mb: 3
                }}
            >
                <Typography
                    color="textSecondary"
                    variant="overline"
                    sx={{mx: 1}}
                >
                    {`${ticketTitle}`}
                </Typography>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    {
                        loading ? (
                            <Box>
                                <Loading/>
                            </Box>
                        ) : (
                            <Button
                                disabled={!floorPriceData}
                                component={floorPriceData ? "a" : "button"}
                                href={ticketMarketplaceLink}
                                target={"_blank"}
                                rel={"noreferrer"}
                                endIcon={!floorPriceData ? false :
                                    <OpenInNewIcon sx={{color: theme.palette.text.primary}}/>}
                            >
                                <Box
                                    sx={{width: 25, height: 25, mr: 1}}
                                >
                                    <TetherLogo/>
                                </Box>
                                <Typography variant="h5" color={theme.palette.text.primary}>
                                    {floorPriceData ? floorPriceData.price?.toLocaleString() : '--'}
                                </Typography>
                            </Button>
                        )
                    }
                </Box>
            </Box>
        </Card>
    )
}
