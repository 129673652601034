import { ReactNode, useMemo } from "react";
import type { FC } from "react";
import PropTypes from "prop-types";

import {
  Box,
  Button,
  Divider,
  Drawer,
  Typography,
  useMediaQuery,
  Stack,
  Link,
  IconButton,
} from "@mui/material";
import type { Theme } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { SidenavSection } from "./SidenavSection";
import LogoSmallWhite from "../../../assets/New Apollo Logo White.svg";
import { ApolloLogo } from "../icons/apollo-logo";
import PegaxyTokensInfo from "../../web3/pegaxy-tokens-info";
import { BurnModal } from "../../web3/Burn/BurnModal";

import { DiscordIcon } from "../../../components/layout/icons/discord";
import { TelegramIcon } from "../../../components/layout/icons/telegram";
import { FacebookIcon } from "../../../components/layout/icons/facebook";
import { TwitterIcon } from "../../../components/layout/icons/twitter";

interface SidenavProps {
  onClose: () => void;
  open: boolean;
}

interface Item {
  title: string;
  children?: Item[];
  chip?: ReactNode;
  icon?: ReactNode;
  path?: string;
  depth?: Number;
}

interface Section {
  title?: string;
  items: Item[];
  privateSection: boolean;
}

const getSections = (): Section[] => [
  {
    title: "Personal",
    items: [
      {
        title: "Dashboard",
        path: "/personal/dashboard",
        depth: 0,
      },
      {
        title: "Pega",
        path: "/personal/pega",
        depth: 0,
      },
    ],
    privateSection: true,
  },
  {
    title: "Metaverse",
    items: [
      {
        title: "Overview",
        path: "/",
        depth: 0,
      },
      {
        title: "Leaderboard",
        path: "/leaderboard",
        depth: 0,
      },
      {
        title: "Liquidity Pool",
        path: "/liquidity",
        depth: 0,
      },
    ],
    privateSection: false,
  },
  {
    title: "Pegas",
    items: [
      {
        title: "Fusing",
        path: "/pega-fusing",
        depth: 0,
      },
      {
        title: "Breeding",
        path: "/pega-breeding",
        depth: 0
      },
    ],
    privateSection: false,
  },
  {
    title: "Events",
    items: [
      {
        title: "Valentine",
        path: "/event/valentine",
        depth: 0,
      },
    ],
    privateSection: false,
  },
];

export const Sidenav: FC<SidenavProps> = (props) => {
  const { onClose, open } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"), {
    noSsr: true,
  });
  const sections = useMemo(() => getSections(), []);

  const { pathname } = useLocation();

  const content = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div>
          <Box sx={{ p: 3, pb: 1 }}>
            <NavLink
              to="/"
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <img
                style={{
                  maxWidth: "100%",
                  height: 40,
                }}
                src={LogoSmallWhite}
                alt=""
              />
              <Box
                sx={{
                  ml: 4,
                  mr: 2,
                  mt: 1,
                }}
              >
                <ApolloLogo />
              </Box>
            </NavLink>
          </Box>
        </div>
        <Divider
          sx={{
            borderColor: "#2D3748", // dark divider
            mt: 2,
          }}
        />
        <PegaxyTokensInfo />
        <BurnModal />
        <Box sx={{ flexGrow: 1 }}>
          {sections.map((section) => {
            // if (!metamaskAccount && section.privateSection) {
            //     return null
            // }

            return (
              //@ts-ignore
              <SidenavSection
                key={section.title}
                path={pathname}
                sx={{
                  mt: 2,
                  "& + &": {
                    mt: 2,
                  },
                }}
                {...section}
              />
            );
          })}
        </Box>

        <Divider
          sx={{
            borderColor: "#2D3748", // dark divider
          }}
        />
        <Box sx={{ px: 3, py: 2 }}>
          <Box>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Link
                component={IconButton}
                href={`https://facebook.com/pegaxyofficial`}
                target="_blank"
                rel="noopener"
              >
                <FacebookIcon fontSize="small" sx={{ color: "#ffffff" }} />
              </Link>
              <Link
                component={IconButton}
                href={`https://twitter.com/pegaxyofficial`}
                target="_blank"
                rel="noopener"
              >
                <TwitterIcon fontSize="small" sx={{ color: "#ffffff" }} />
              </Link>
              <Link
                component={IconButton}
                href={`https://discord.gg/pegaxy`}
                target="_blank"
                rel="noopener"
              >
                <DiscordIcon fontSize="small" sx={{ color: "#ffffff" }} />
              </Link>
              <Link
                component={IconButton}
                href={`https://t.me/pegaxyglobal`}
                target="_blank"
                rel="noopener"
              >
                <TelegramIcon fontSize="small" sx={{ color: "#ffffff" }} />
              </Link>
            </Stack>
          </Box>
        </Box>
        <Divider
          sx={{
            borderColor: "#2D3748", // dark divider
          }}
        />
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "neutral.900",
            borderRightColor: "divider",
            borderRightStyle: "solid",
            borderRightWidth: (theme) =>
              theme.palette.mode === "dark" ? 1 : 0,
            color: "#FFFFFF",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.900",
          color: "#FFFFFF",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

Sidenav.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
