import { FC } from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import useValentineSupplyData from "./useValentineSupplyData";
import { VISIcon } from "../../layout/icons/vis-icon";
import { Loading } from "../../utils/Loading";

export const ValentineSupplyData: FC = () => {
    const {
        totalVISBurned,
        usedRare,
        usedEpic,
        usedLegendary,
        loadingSupply
    } = useValentineSupplyData();

    // const loadingSupply = false;
    // const rareSupply = 0;
    // const epicSupply = 0;
    // const legendarySupply = 0;
    // const totalVISBurned = 42497500;

    return (
        <Box
            sx={{
                backgroundColor: 'background.default',
                p: 2,
                pb: 1
            }}
        >
            <Grid
                container
                spacing={3}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Card sx={{ boxShadow: 10 }}>
                        {
                            loadingSupply ? (
                                <Box
                                    sx={{
                                        minHeight: 150,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Loading />
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        p: 4,
                                        alignItems: 'center',
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    <Box
                                    >
                                        <VISIcon />
                                    </Box>
                                    <Box sx={{ ml: 3 }}>
                                        <Typography
                                            color="textSecondary"
                                            noWrap
                                            variant="overline"
                                        >
                                            Total VIS Burned
                                        </Typography>
                                        <Typography variant="h4">
                                            {totalVISBurned?.toLocaleString()}
                                        </Typography>
                                    </Box>
                                </Box>
                            )
                        }
                    </Card>
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <Card sx={{ boxShadow: 10 }}>
                        <Box
                            sx={{
                                p: 2,
                                textAlign: 'center',
                                borderBottom: (theme) => (
                                    {
                                        xs: `1px solid ${theme.palette.divider}`
                                    }
                                ),
                            }}
                        >
                            <Typography
                                variant={"h6"}
                            >
                                Tickets applied to Breed
                            </Typography>
                        </Box>
                        <Grid
                            alignItems="center"
                            container
                            justifyContent="space-between"
                            flexWrap="wrap"
                        >
                            <Grid
                                item
                                sx={{
                                    minWidth: 150,
                                    flex: 1,
                                    borderRight: (theme) => (
                                        {
                                            md: `1px solid ${theme.palette.divider}`
                                        }
                                    ),
                                    p: 3,
                                    textAlign: 'center'
                                }}
                            >
                                <Typography
                                    color="textSecondary"
                                    component="h2"
                                    gutterBottom
                                    variant="overline"
                                >
                                    Legendary
                                </Typography>
                                {
                                    loadingSupply ? (
                                        <Box
                                            sx={{
                                                minHeight: 150,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Loading />
                                        </Box>
                                    ) : (
                                        <Box
                                            sx={{
                                                alignItems: 'center',
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Typography
                                                variant="h5"
                                            >
                                                {usedLegendary?.toLocaleString()} / 500
                                            </Typography>
                                        </Box>
                                    )
                                }
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    minWidth: 150,
                                    flex: 1,
                                    borderRight: (theme) => (
                                        {
                                            md: `1px solid ${theme.palette.divider}`
                                        }
                                    ),
                                    p: 3,
                                    textAlign: 'center'
                                }}
                            >
                                <Typography
                                    color="textSecondary"
                                    component="h2"
                                    gutterBottom
                                    variant="overline"
                                >
                                    Epic
                                </Typography>
                                {
                                    loadingSupply ? (
                                        <Box
                                            sx={{
                                                minHeight: 150,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Loading />
                                        </Box>
                                    ) : (
                                        <Box
                                            sx={{
                                                alignItems: 'center',
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Typography
                                                variant="h5"
                                            >
                                                {usedEpic?.toLocaleString()} / 1000
                                            </Typography>
                                        </Box>
                                    )
                                }
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    minWidth: 150,
                                    flex: 1,
                                    borderRight: (theme) => (
                                        {
                                            md: `1px solid ${theme.palette.divider}`
                                        }
                                    ),
                                    p: 3,
                                    textAlign: 'center'
                                }}
                            >
                                <Typography
                                    color="textSecondary"
                                    component="h2"
                                    gutterBottom
                                    variant="overline"
                                >
                                    Rare
                                </Typography>
                                {
                                    loadingSupply ? (
                                        <Box
                                            sx={{
                                                minHeight: 150,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Loading />
                                        </Box>
                                    ) : (
                                        <Box
                                            sx={{
                                                alignItems: 'center',
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Typography
                                                variant="h5"
                                            >
                                                {usedRare?.toLocaleString()} / 1,500
                                            </Typography>
                                        </Box>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}
