import humanizeDuration from "humanize-duration";

export const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: "shortEn",
  languages: {
    shortEn: {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "m",
      s: () => "s",
      ms: () => "ms",
    },
  },
});

export const humanizerOptions = {
  round: true,
  largest: 3,
  spacer: "",
  delimiter: " ",
};

export const abbreviateId = (id?: string) => {
  if (!id) return "";
  return id.slice(0, 6) + "..." + id.slice(-6);
};
