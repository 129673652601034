import { useEffect, useRef, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { useEagerConnect, useInactiveListener } from "../web3/hooks";
import { CachePolicies, useFetch } from "use-http";

export default function useWeb3Auth() {
  // const { get: getCurrentUser, loading: loadingCurrentUser, error: errorCurrentUser } = useFetch(`${process.env.REACT_APP_BASE_API_URL}/auth/me`, {
  //     loading: false,
  //     headers: {
  //         'Authorization': `Bearer ${accessToken.current}`
  //     }
  // }, [accessToken.current]);

  const [activatingConnector, setActivatingConnector] = useState<any>();

  const context = useWeb3React<Web3Provider>();
  const {
    connector,
    account: metamaskAccount,
    error: metamaskError,
    activate,
    library,
    deactivate,
  } = context;

  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager || !!activatingConnector);

  const getUserData = async () => {
    // const data = await getCurrentUser();
    // authDispatch({
    //     type: AuthActionType.InitCurrentUser,
    //     payload: data
    // })
  };

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  return {
    connector,
    metamaskError,
    library,
    metamaskAccount,
    setActivatingConnector,
    activate,
    deactivate,
  };
}
