export enum PegaBloodLine {
    Hoz = 'Hoz',
    Campona = 'Campona',
    Klin = 'Klin',
    Zan = 'Zan'
}

export enum PegaBloodLineBreedCooldownMillis {
    Hoz = 24 * 3600000,
    Campona = 48 * 3600000,
    Klin = 72 * 3600000,
    Zan = 96 * 3600000,
    Baby = 48 * 3600000
}

export enum PegaBloodLineRaceCooldownMillis {
    Hoz = 96 * 3600000,
    Campona = 72 * 3600000,
    Klin = 48 * 3600000,
    Zan = 24 * 3600000,
}

export const MAX_PEGA_RACE_COOLDOWN_MILLIS = 96 * 3600000;
