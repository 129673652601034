export enum GameServices {
    Renting = "RENT_SERVICE",
    Racing = "RACE_SERVICE",
    Resting = "NO_SERVICE",
    Market = "MARKET_SERVICE"
}

export enum RentModes {
    ShareProfit = "SHARE_PROFIT",
    PayRentFee = "PAY_RENT_FEE"
}

export function getFriendlyServiceName(service: GameServices) {
    switch (service) {
        case GameServices.Renting:
            return 'Renting';
        case GameServices.Racing:
            return 'Racing';
        case GameServices.Resting:
            return 'Resting';
        case GameServices.Market:
            return 'For Sale';
        default:
            return '';
    }
}

export function getFriendlyRentModeName(rentMode: RentModes) {
    switch (rentMode) {
        case RentModes.ShareProfit:
            return 'Share Profit';
        case RentModes.PayRentFee:
            return 'PGX Renting';
        default:
            return '';
    }
}
