import { useEffect, useRef, useState } from "react";
import { Avatar, Box, ButtonBase, Typography } from "@mui/material";
import shallow from "zustand/shallow";

import NavWalletIcon from "../../../assets/nav-wallet.png";
import { AccountPopover } from "./AccountPopover";
import { useTheme } from "@mui/material/styles";
import { useAuthStore } from "../../../stores/auth";

export const AccountButton = () => {
  const theme = useTheme();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openPopover, setOpenPopover] = useState<boolean>(false);

  const { user, loading, storedToken, getUser } = useAuthStore(
    ({ user, loading, storedToken, getUser }) => ({
      user,
      loading,
      storedToken,
      getUser,
    }),
    shallow
  );

  useEffect(() => {
    if (storedToken && !user && !loading) {
      getUser(storedToken);
    }
  }, []);

  const handleOpenPopover = (): void => {
    setOpenPopover(true);
  };

  const handleClosePopover = (): void => {
    setOpenPopover(false);
  };

  return (
    <>
      <Box component={ButtonBase} onClick={handleOpenPopover} ref={anchorRef}>
        {!user && !loading ? (
          <Box
            sx={{
              py: 1,
              border: "1px solid #5048E5",
              borderRadius: "20px",
              width: 175,
              right: 0,
              marginRight: "-40px",
            }}
          >
            <Typography
              variant={"caption"}
              sx={{
                mr: 3.75,
                color: theme.palette.text.primary,
              }}
            >
              Unverified Account
            </Typography>
          </Box>
        ) : null}
        <Avatar
          src={""}
          sx={{
            backgroundColor: "#5048E5",
            height: 40,
            width: 40,
            p: 0.75,
          }}
        >
          <img
            style={{ maxWidth: "100%", marginLeft: 2 }}
            src={NavWalletIcon}
            alt=""
          />
        </Avatar>
      </Box>
      <AccountPopover
        user={user}
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        open={openPopover}
      />
    </>
  );
};
