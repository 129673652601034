import {useEffect, useState} from "react";
import axios from "axios";

enum TicketRarity {
    Legendary = "Legendary",
    Epic = "Epic",
    Rare = "Rare"
}

interface SupplyItem {
    ticketRarity: TicketRarity;
}

interface ValentineSupply {
    used: number;
}

interface ValentineSupplyData {
    legendary: ValentineSupply | null;
    epic: ValentineSupply | null;
    rare: ValentineSupply | null;
}


export default function useValentineSupplyData () {
    const [usedRare, setUsedRare] = useState<number | null>(null);
    const [usedEpic, setUsedEpic] = useState<number | null>(null);
    const [usedLegendary, setUsedLegendary] = useState<number | null>(null);

    const [totalVISBurned, setTotalVISBurned] = useState<number>(0);

    const [loadingSupply, setLoadingSupply] = useState<boolean>(true);

    const getSupplyData = async (): Promise<ValentineSupplyData | null> => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/tickets/valentine/circulating-supply`)
            if (data && data.length > 0) {
                return {
                    rare: data.find((i: SupplyItem) => i.ticketRarity === TicketRarity.Rare) || null,
                    epic: data.find((i: SupplyItem) => i.ticketRarity === TicketRarity.Epic) || null,
                    legendary: data.find((i: SupplyItem) => i.ticketRarity === TicketRarity.Legendary) || null
                }
            }
        } catch (err) {
            console.log('[valentineSupplyData] Error Getting Circulating Supply');
        }

        return null;
    }

    useEffect(() => {
        async function getData() {
            setLoadingSupply(true)

            const supplyData: ValentineSupplyData | null = await getSupplyData();

            if (supplyData) {
                // setTotalVISBurned(
                //     (supplyData.rare ? supplyData.rare.sold * RARE_TICKET_PRICE : 0) +
                //     (supplyData.epic ? supplyData.epic.sold * EPIC_TICKET_PRICE : 0) +
                //     (supplyData.legendary ? supplyData.legendary.sold * LEGENDARY_TICKET_PRICE : 0)
                // )

                setTotalVISBurned(42497500)

                setUsedRare(supplyData.rare?.used ? supplyData.rare.used : null);
                setUsedEpic(supplyData.epic?.used ? supplyData.epic.used : null);
                setUsedLegendary(supplyData.legendary?.used ? supplyData.legendary.used : null);
            }

            setLoadingSupply(false)
        }

        getData();
    }, [])

    return {
        totalVISBurned,
        usedRare,
        usedEpic,
        usedLegendary,
        loadingSupply
    }
}
