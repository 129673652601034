import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import axios from "axios";
import { PgxIcon } from "../components/layout/icons/pgx-icon";
import { TetherLogo } from "../components/layout/icons/tether-icon";
import { VISIcon } from "../components/layout/icons/vis-icon";
import { MaticLogo } from "../components/layout/icons/matic-icon";
import { VISLockedIcon } from "../components/layout/icons/vis-locked-icon";

const GENERIC_ERC20_ABI = [
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "balance",
        type: "uint256",
      },
    ],
    payable: false,
    type: "function",
  },
];

const OTHER_TOKEN_ADDRESSES = [
  {
    address: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    abbr: "USDT",
    name: "USD Tether",
    icon: <TetherLogo />,
  },
  {
    address: "0xc1c93D475dc82Fe72DBC7074d55f5a734F8cEEAE",
    abbr: "PGX",
    name: "Pegaxy Stone",
    icon: <PgxIcon />,
  },
  {
    address: "0xcC1B9517460D8aE86fe576f614d091fCa65a28Fc",
    abbr: "VIS",
    name: "Vigorus",
    icon: <VISIcon />,
  },
];

export interface TokenBalance {
  address?: string;
  name?: string;
  abbr?: string;
  value?: string;
  icon?: any;
}

export default function useTokenBalances() {
  const [loading, setLoading] = useState<boolean>(false);
  const [balances, setBalances] = useState<Array<TokenBalance>>([]);
  const { account, library: web3 } = useWeb3React();

  const getNativeTokenBalance = async (web3: any) => {
    if (!account) return null;

    const nativeTokenBalance = await web3.eth.getBalance(account);
    const tokenBalance = web3.utils.fromWei(nativeTokenBalance);

    return {
      address: "NATIVE-TOKEN",
      abbr: "MATIC",
      name: "Polygon",
      value: tokenBalance,
      icon: <MaticLogo />,
    };
  };

  const getOtherTokenBalances = async (web3: any) => {
    let tokenBalances = [];

    for (let i = 0; i < OTHER_TOKEN_ADDRESSES.length; i++) {
      let tokenBalance = "";

      const tokenInst = new web3.eth.Contract(
        GENERIC_ERC20_ABI,
        OTHER_TOKEN_ADDRESSES[i].address
      );
      const balance = await tokenInst.methods.balanceOf(account).call();

      if (OTHER_TOKEN_ADDRESSES[i].abbr === "USDT") {
        tokenBalance = web3.utils.fromWei(balance, "mwei");
      } else {
        tokenBalance = web3.utils.fromWei(balance);
      }

      tokenBalances.push({
        address: OTHER_TOKEN_ADDRESSES[i].address,
        abbr: OTHER_TOKEN_ADDRESSES[i].abbr,
        name: OTHER_TOKEN_ADDRESSES[i].name,
        icon: OTHER_TOKEN_ADDRESSES[i].icon,
        value: tokenBalance,
      });
    }

    return tokenBalances;
  };

  const getLockedVISBalance = async (web3: any) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/assets/count/user/${account}`
      );

      const stringVIS = data.lockedVis.toFixed(2);
      return {
        address: "OFF-CHAIN-VIS",
        abbr: "VIS (Locked)",
        name: "Vigorus",
        value: stringVIS,
        icon: <VISLockedIcon />,
      };
    } catch (err) {
      return null;
    }
  };

  const getWeb3Access = async () => {
    let web3;

    // @ts-ignore
    if (typeof window.ethereum !== "undefined") {
      // @ts-ignore
      web3 = new Web3(window.ethereum);
      try {
        // @ts-ignore
        await window.ethereum.enable();
        return web3;
      } catch (e) {
        // User denied access
        return null;
      }
    }
  };

  const getAllBalances = () => {
    setLoading(true);
    getWeb3Access().then((web3) => {
      Promise.all([
        getNativeTokenBalance(web3),
        getOtherTokenBalances(web3),
        getLockedVISBalance(web3),
      ]).then(([nativeTokenBalance, otherTokenBalances, lockedVISBalance]) => {
        setLoading(false);
        setBalances([
          ...otherTokenBalances,
          { ...lockedVISBalance },
          { ...nativeTokenBalance },
        ]);
      });
    });
  };

  useEffect(() => {
    getAllBalances();
  }, [web3]);

  return {
    getAllBalances,
    balances,
    loading,
  };
}
