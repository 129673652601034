import React, {FC} from "react";
import {Helmet} from "react-helmet";
import {Box, Container, Grid, Typography} from "@mui/material";
import {ValentineSupplyData} from "../../../../components/events/valentine/ValentineSupplyData";
import {ValentineTicketFloorPrices} from "../../../../components/events/valentine/ValentineTicketFloorPrices";
import {ValentinePegaFloorPrices} from "../../../../components/events/valentine/ValentinePegaFloorPrices";

export const ValentineEventPage: FC = () => {
    return (
        <>
            <Helmet>
                <title>Valentine | Pegaxy Apollo</title>
            </Helmet>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 6
                }}
            >
                <Container maxWidth={"xl"}>
                    <Box>
                        <Grid
                            container
                            justifyContent="space-between"
                            spacing={3}
                            sx={{ mb: 4 }}
                        >
                            <Grid
                                item
                            >
                                <Typography variant="h4">
                                    Valentine Event 2022
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                            >
                                <ValentineSupplyData/>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <ValentineTicketFloorPrices/>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <ValentinePegaFloorPrices/>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </>
    )
}
