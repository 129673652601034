import {
  Box,
  Container,
  Grid,
  MenuItem,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useWeb3React } from "@web3-react/core";
import { ConnectButton } from "../../../components/web3/metamask-connect-btn";
import { useFetch } from "use-http";
import moment from "moment";
import shallow from "zustand/shallow";

import { Timeframe } from "../../../interfaces/timeframe";
import {
  EarningsSummary,
  EarningsSummaryData,
} from "../../../components/personal/dashboard/EarningsSummary/EarningsSummary";
import { timeframeToEpochSeconds } from "../../../utils/timeframeToDate";
import { PegaTableData } from "../../../components/personal/pega/PegaTable/PegaTable";
import { GameServices, RentModes } from "../../../interfaces/game-services";
import { groupBy, map } from "lodash";
import {
  ProfitShareBucketsData,
  RentingSummary,
} from "../../../components/personal/dashboard/RentingSummary/RentingSummary";
import { RacingSummary } from "../../../components/personal/dashboard/RacingSummary/RacingSummary";
import { useEagerConnect, useInactiveListener } from "../../../web3/hooks";
import { injected } from "../../../web3/connectors";
import { usePreferenceStore } from "../../../stores/preferences";
import { useTheme } from "@mui/material/styles";
import useLiveClockUTC from "../../../hooks/useLiveClockUTC";

export const DashboardPage: FC = () => {
  const theme = useTheme();
  const { preferences, updatePreferences } = usePreferenceStore(
    ({ preferences, updatePreferences }) => ({
      preferences,
      updatePreferences,
    }),
    shallow
  );
  const liveClockUTC = useLiveClockUTC();

  const {
    connector: metamaskConnector,
    account: metamaskAccount,
    activate: activateMetamask,
  } = useWeb3React();

  const [activatingConnector, setActivatingConnector] = useState<any>();
  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager || !!activatingConnector);

  useEffect(() => {
    if (activatingConnector && activatingConnector === metamaskConnector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, metamaskConnector]);

  const handleConnectClick = () => {
    setActivatingConnector(injected);
    activateMetamask(injected);
  };

  const [populatingEarningsData, setPopulatingEarningsData] =
    useState<boolean>(false);
  const [visEarningsChartData, setVisEarningsChartData] =
    useState<EarningsSummaryData>({ total: 0, dataset: [] });
  const [visEarningsChartLabels, setVisEarningsChartLabels] = useState<
    Array<string>
  >([]);
  const [pgxEarningsChartData, setPgxEarningsChartData] =
    useState<EarningsSummaryData>({ total: 0, dataset: [] });
  const [pgxEarningsChartLabels, setPgxEarningsChartLabels] = useState<
    Array<string>
  >([]);
  const [usdtEarningsChartData, setUsdtEarningsChartData] =
    useState<EarningsSummaryData>({ total: 0, dataset: [] });
  const [usdtEarningsChartLabels, setUsdtEarningsChartLabels] = useState<
    Array<string>
  >([]);

  const [populatingRentingData, setPopulatingRentingData] =
    useState<boolean>(false);
  const [totalPega, setTotalPega] = useState<number>(0);
  const [totalMarketPega, setTotalMarketPega] = useState<number>(0);
  const [totalRentedPega, setTotalRentedPega] = useState<number>(0);
  const [totalPayRentFeePega, setTotalPayRentFeePega] = useState<number>(0);
  const [totalProfitSharePega, setTotalProfitSharePega] = useState<number>(0);
  const [profitShareBuckets, setProfitShareBuckets] = useState<
    Array<ProfitShareBucketsData>
  >([]);
  const [payRentFeeBuckets, setPayRentFeeBuckets] = useState<
    Array<ProfitShareBucketsData>
  >([]);

  const [populatingGameStatsData, setPopulatingGameStatsData] =
    useState<boolean>(false);
  const [totalRaces, setTotalRaces] = useState<number>(0);
  const [totalGoldRaces, setTotalGoldRaces] = useState<number>(0);
  const [totalSilverRaces, setTotalSilverRaces] = useState<number>(0);
  const [totalBronzeRaces, setTotalBronzeRaces] = useState<number>(0);
  const [totalLostRaces, setTotalLostRaces] = useState<number>(0);
  const [racesWinRate, setRacesWinRate] = useState<string>("");

  const {
    get: getEarningsData,
    loading: loadingEarningsData,
    error: errorEarningsData,
  } = useFetch(`${process.env.REACT_APP_BASE_API_URL}/earnings`, {
    loading: true,
    retries: 5,
    cacheLife: 1 * 60000, // 1 minutes
    persist: false,
  });

  const {
    get: getPegaData,
    loading: loadingPegaData,
    error: errorPegaData,
  } = useFetch(`${process.env.REACT_APP_BASE_API_URL}/pegas/owner`, {
    loading: true,
    retries: 5,
    cacheLife: 1 * 60000, // 1 minutes
    persist: false,
  });

  const {
    get: getGameStatsData,
    loading: loadingGameStatsData,
    error: errorGameStatsData,
  } = useFetch(`${process.env.REACT_APP_BASE_API_URL}/stats/game`, {
    loading: true,
    retries: 5,
    cacheLife: 1 * 60000, // 1 minutes
    persist: false,
  });

  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  const populateEarningsData = async () => {
    setPopulatingEarningsData(true);
    const data = await getEarningsData(
      `/historical/user/${metamaskAccount}${
        timeframeToEpochSeconds(preferences.timeframe)
          ? "?since=" + timeframeToEpochSeconds(preferences.timeframe)
          : ""
      }`
    );

    let labelsArr = [];

    let visEarningsDataset = [];
    let pgxEarningsDataset = [];
    let usdtEarningsDataset = [];

    let totalVisEarned = data.reduce(
      (p: number, c: any) =>
        p +
        (c.ownRacedVis +
          c.renteeVisShare +
          c.fixedRenterVis +
          c.sharedRenterVis),
      0
    );
    let totalPgxEarned = data.reduce(
      (p: number, c: any) => p + c.fixedRentalPgx,
      0
    );
    let totalUsdtEarned = data.reduce(
      (p: number, c: any) => p + c.totalPegaSellUSDT,
      0
    );

    for (let i = 0; i < data.length; i++) {
      labelsArr.push(moment.unix(data[i].epoch).format("DD-MMM-YY"));
      visEarningsDataset.push(data[i].ownRacedVis + data[i].renteeVisShare);
      pgxEarningsDataset.push(data[i].fixedRentalPgx);
      usdtEarningsDataset.push(data[i].totalPegaSellUSDT);
    }

    // @ts-ignore
    const uniqueLabels = [...new Set(labelsArr)];
    setVisEarningsChartLabels(uniqueLabels);
    setPgxEarningsChartLabels(uniqueLabels);
    setUsdtEarningsChartLabels(uniqueLabels);

    setVisEarningsChartData({
      dataset: [
        {
          name: "VIS Earnings",
          data: visEarningsDataset,
        },
      ],
      total: totalVisEarned,
    });

    setPgxEarningsChartData({
      dataset: [
        {
          name: "PGX Earnings",
          data: pgxEarningsDataset,
        },
      ],
      total: totalPgxEarned,
    });

    setUsdtEarningsChartData({
      dataset: [
        {
          name: "USDT Earnings",
          data: usdtEarningsDataset,
        },
      ],
      total: totalUsdtEarned,
    });

    setPopulatingEarningsData(false);
  };

  const populateRentingData = async () => {
    setPopulatingRentingData(true);

    const data = await getPegaData(`/user/${metamaskAccount}`);

    const profitSharePegas = data.filter(
      (pega: PegaTableData) =>
        pega.service === GameServices.Renting &&
        pega.lastRenterRentMode === RentModes.ShareProfit
    );

    const payRentFeePegas = data.filter(
      (pega: PegaTableData) =>
        pega.service === GameServices.Renting &&
        pega.lastRenterRentMode === RentModes.PayRentFee
    );

    const totalRentingPegas = profitSharePegas.length + payRentFeePegas.length;
    const totalInMarketPegas = data.filter(
      (pega: PegaTableData) => pega.service === GameServices.Market
    ).length;
    const totalPegaValue = data.length;

    const profitShareBuckets = groupBy(profitSharePegas, "lastRenterPrice");
    const payRentFeeAmountBuckets = groupBy(payRentFeePegas, "lastRenterPrice");

    setTotalPega(totalPegaValue);
    setTotalMarketPega(totalInMarketPegas);
    setTotalRentedPega(totalRentingPegas);

    setTotalPayRentFeePega(payRentFeePegas.length);
    setTotalProfitSharePega(profitSharePegas.length);

    setProfitShareBuckets(
      map(profitShareBuckets, (items: any, bucket: any) => {
        return {
          bucket: Number(bucket),
          total: items.length,
        };
      })
    );

    setPayRentFeeBuckets(
      map(payRentFeeAmountBuckets, (items: any, bucket: any) => {
        return {
          bucket: Number(bucket),
          total: items.length,
        };
      })
    );

    setPopulatingRentingData(false);
  };

  const populateGameStatsData = async () => {
    setPopulatingGameStatsData(true);
    const data = await getGameStatsData(
      `/total/user/${metamaskAccount}${
        timeframeToEpochSeconds(preferences.timeframe)
          ? "?since=" + timeframeToEpochSeconds(preferences.timeframe)
          : ""
      }`
    );

    setTotalRaces(data.totalRaces);
    setTotalGoldRaces(data.gold);
    setTotalSilverRaces(data.silver);
    setTotalBronzeRaces(data.bronze);

    const totalWonRaces = data.gold + data.silver + data.bronze;

    setTotalLostRaces(data.totalRaces - totalWonRaces);

    setRacesWinRate(((totalWonRaces / data.totalRaces) * 100).toFixed(1) + "%");

    setPopulatingGameStatsData(false);
  };

  useEffect(() => {
    if (metamaskAccount) {
      populateEarningsData();
      populateRentingData();
      populateGameStatsData();
    }
  }, [metamaskAccount, preferences.timeframe]);

  return (
    <>
      <Helmet>
        <title>Dashboard | Pegaxy Apollo</title>
      </Helmet>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 6,
        }}
      >
        <Container
          maxWidth={"lg"}
          sx={{
            display: metamaskAccount ? "block" : "flex",
            height: metamaskAccount ? "auto" : "calc(100vh - 180px)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {metamaskAccount ? (
            <Box sx={{ mb: 4 }}>
              <Grid
                container
                justifyContent="space-between"
                spacing={3}
                sx={{ px: 2 }}
              >
                <Grid item>
                  <Typography variant="h4">Dashboard</Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    m: -1,
                  }}
                >
                  {!isSm ? (
                    <Typography
                      variant="caption"
                      sx={{
                        color: theme.palette.text.primary,
                      }}
                    >
                      {`(UTC - ${liveClockUTC})`}
                    </Typography>
                  ) : null}
                  <TextField
                    value={preferences.timeframe}
                    label="Period"
                    select
                    size="small"
                    sx={{ m: 1, minWidth: 200 }}
                    onChange={(e) => {
                      const timeframe: Timeframe = e.target.value as Timeframe;
                      updatePreferences({
                        ...preferences,
                        timeframe: timeframe,
                      });
                      // setTimeframe(timeframe)
                    }}
                  >
                    <MenuItem value="today">Today</MenuItem>
                    <MenuItem value="yesterday">Today + Yesterday</MenuItem>
                    <MenuItem value="week">Last 7 days</MenuItem>
                    <MenuItem value="biweek">Last 15 days</MenuItem>
                    <MenuItem value="month">Last month</MenuItem>
                    <MenuItem value="all">All</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <EarningsSummary
                    visEarnedData={visEarningsChartData}
                    visEarnedLabels={visEarningsChartLabels}
                    pgxEarnedData={pgxEarningsChartData}
                    pgxEarnedLabels={pgxEarningsChartLabels}
                    usdtEarnedData={usdtEarningsChartData}
                    usdtEarnedLabels={usdtEarningsChartLabels}
                    loading={loadingEarningsData || populatingEarningsData}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RentingSummary
                    totalPega={totalPega}
                    totalMarketPega={totalMarketPega}
                    totalRentedPega={totalRentedPega}
                    totalPayRentFeePega={totalPayRentFeePega}
                    totalProfitSharePega={totalProfitSharePega}
                    profitShareBuckets={profitShareBuckets}
                    payRentFeeBuckets={payRentFeeBuckets}
                    loading={loadingPegaData || populatingRentingData}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RacingSummary
                    totalRaces={totalRaces}
                    totalGoldRaces={totalGoldRaces}
                    totalSilverRaces={totalSilverRaces}
                    totalBronzeRaces={totalBronzeRaces}
                    totalLostRaces={totalLostRaces}
                    winRate={racesWinRate}
                    loading={loadingGameStatsData || populatingGameStatsData}
                  />
                </Grid>
                {/*<Grid*/}
                {/*    item*/}
                {/*    xs={12}*/}
                {/*>*/}
                {/*    <Grid*/}
                {/*        container*/}
                {/*    >*/}
                {/*        <Grid*/}
                {/*            item*/}
                {/*            md={6}*/}
                {/*            sm={12}*/}
                {/*            xs={12}*/}
                {/*        >*/}
                {/*            <TotalRacesChart/>*/}
                {/*        </Grid>*/}
                {/*        <Grid*/}
                {/*            item*/}
                {/*            md={6}*/}
                {/*            sm={12}*/}
                {/*            xs={12}*/}
                {/*        >*/}
                {/*            <RentingSummary/>*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
              </Grid>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  maxWidth: 400,
                  textAlign: "center",
                  mb: 3,
                }}
              >
                You need to connect your Polygon account to access this page
              </Typography>
              <Box>
                <ConnectButton onConnect={() => handleConnectClick()} />
              </Box>
            </Box>
          )}
        </Container>
      </Box>
    </>
  );
};
