import { FC } from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import useBurnedPegaData from "./useBurnedPegaData";
import { Loading } from "../../utils/Loading";
import { VISIcon } from "../../layout/icons/vis-icon";
import { PgxIcon } from "../../layout/icons/pgx-icon";

interface PegaFusedSummaryDataProps {
    showTypes?: boolean,
    totalBredPega: number;
}

const PegaFusedSummaryData: FC<PegaFusedSummaryDataProps> = ({
    showTypes = true,
    totalBredPega = 0,
}) => {
    const {
        totalVISBurnedBreed,
        totalPGXSpentBreed,
        totalPegaBurned,
        burnedPacer,
        burnedRare,
        burnedEpic,
        loadingBurnedBreakdown,
        loadingSpentBreedData
    } = useBurnedPegaData();

    return (
        <Box
            sx={{
                backgroundColor: 'background.default',
                p: 2,
                pb: 1
            }}
        >
            <Grid
                container
                spacing={3}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Card sx={{ boxShadow: 10 }}>
                        {
                            (loadingSpentBreedData) ? (
                                <Box
                                    sx={{
                                        minHeight: 150,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Loading />
                                </Box>
                            ) : (
                                <Grid
                                    alignItems="center"
                                    container
                                    justifyContent="space-between"
                                    flexWrap="wrap"
                                >
                                    <Grid item>
                                        <Box
                                            sx={{
                                                p: 4,
                                                alignItems: 'center',
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                flexWrap: 'wrap'
                                            }}
                                        >
                                            <Box
                                                sx={
                                                    {
                                                        mt: 4
                                                    }
                                                }
                                            >
                                                <VISIcon />
                                            </Box>
                                            <Box sx={{ ml: 2 }}>
                                                <Typography
                                                    color="textSecondary"
                                                    noWrap
                                                    variant="overline"
                                                >
                                                    Total VIS Burned
                                                </Typography>
                                                <Typography variant="h4">
                                                    {totalVISBurnedBreed?.toLocaleString()}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Box
                                            sx={{
                                                p: 4,
                                                alignItems: 'center',
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                flexWrap: 'wrap',
                                                mr: 10
                                            }}
                                        >
                                            <Box
                                            >

                                            </Box>
                                            <Box sx={{ ml: 1 }}>
                                                <Typography
                                                    color="textSecondary"
                                                    noWrap
                                                    variant="overline"
                                                >
                                                    Total Bred Pega
                                                </Typography>
                                                <Typography variant="h4">
                                                    {totalBredPega?.toLocaleString()}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Box
                                            sx={{
                                                p: 4,
                                                alignItems: 'center',
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                flexWrap: 'wrap'
                                            }}
                                        >
                                            <Box
                                                sx={
                                                    {
                                                        mt: 4
                                                    }
                                                }
                                            >
                                                <PgxIcon />
                                            </Box>
                                            <Box sx={{ ml: 1 }}>
                                                <Typography
                                                    color="textSecondary"
                                                    noWrap
                                                    variant="overline"
                                                >
                                                    Total PGX Spent
                                                </Typography>
                                                <Typography variant="h4">
                                                    {totalPGXSpentBreed?.toLocaleString()}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Card>
                </Grid>
                {showTypes &&
                    <Grid
                        item
                        xs={12}
                    >
                        <Card sx={{ boxShadow: 10 }}>
                            <Box
                                sx={{
                                    p: 2,
                                    textAlign: 'center',
                                    borderBottom: (theme) => (
                                        {
                                            xs: `1px solid ${theme.palette.divider}`
                                        }
                                    ),
                                }}
                            >
                                <Typography
                                    variant={"h6"}
                                >
                                    Breed Types Burned
                                </Typography>
                            </Box>
                            <Grid
                                alignItems="center"
                                container
                                justifyContent="space-between"
                                flexWrap="wrap"
                            >
                                <Grid
                                    item
                                    sx={{
                                        minWidth: 150,
                                        flex: 1,
                                        borderRight: (theme) => (
                                            {
                                                md: `1px solid ${theme.palette.divider}`
                                            }
                                        ),
                                        p: 3,
                                        textAlign: 'center'
                                    }}
                                >
                                    <Typography
                                        color="textSecondary"
                                        component="h2"
                                        gutterBottom
                                        variant="overline"
                                    >
                                        Pacer
                                    </Typography>
                                    {
                                        (loadingBurnedBreakdown || loadingSpentBreedData) ? (
                                            <Box
                                                sx={{
                                                    minHeight: 150,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Loading />
                                            </Box>
                                        ) : (
                                            <Box
                                                sx={{
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <Typography
                                                    variant="h5"
                                                >
                                                    {burnedPacer?.toLocaleString()}
                                                </Typography>
                                            </Box>
                                        )
                                    }
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        minWidth: 150,
                                        flex: 1,
                                        borderRight: (theme) => (
                                            {
                                                md: `1px solid ${theme.palette.divider}`
                                            }
                                        ),
                                        p: 3,
                                        textAlign: 'center'
                                    }}
                                >
                                    <Typography
                                        color="textSecondary"
                                        component="h2"
                                        gutterBottom
                                        variant="overline"
                                    >
                                        Rare
                                    </Typography>
                                    {
                                        (loadingBurnedBreakdown || loadingSpentBreedData) ? (
                                            <Box
                                                sx={{
                                                    minHeight: 150,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Loading />
                                            </Box>
                                        ) : (
                                            <Box
                                                sx={{
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <Typography
                                                    variant="h5"
                                                >
                                                    {burnedRare?.toLocaleString()}
                                                </Typography>
                                            </Box>
                                        )
                                    }
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        minWidth: 150,
                                        flex: 1,
                                        borderRight: (theme) => (
                                            {
                                                md: `1px solid ${theme.palette.divider}`
                                            }
                                        ),
                                        p: 3,
                                        textAlign: 'center'
                                    }}
                                >
                                    <Typography
                                        color="textSecondary"
                                        component="h2"
                                        gutterBottom
                                        variant="overline"
                                    >
                                        Epic
                                    </Typography>
                                    {
                                        (loadingBurnedBreakdown || loadingSpentBreedData) ? (
                                            <Box
                                                sx={{
                                                    minHeight: 150,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Loading />
                                            </Box>
                                        ) : (
                                            <Box
                                                sx={{
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <Typography
                                                    variant="h5"
                                                >
                                                    {burnedEpic?.toLocaleString()}
                                                </Typography>
                                            </Box>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                }
            </Grid>
        </Box>
    );
};

export default PegaFusedSummaryData;
