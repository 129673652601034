import axios from "axios";

const authBaseURL = `${process.env.REACT_APP_BASE_API_URL}/auth`;

export const getCurrentUser = async (accessToken: string) => {
    if (!accessToken) return null;

    try {
        const { data: currentUser } = await axios.get(`${authBaseURL}/me`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });

        const { data: pgxBalances } = await axios.get(`https://api-themis.pegaxy.io/v1/chain/pgx-balance/${currentUser.publicAddress}`);

        return {
            ...currentUser,
            ...pgxBalances,
        };
    } catch (err) {
        console.log('[getCurrentUser] Err', err);
        return null;
    }
};

export const getNonce = async (address: string) => {
    try {
        const { data: nonceData } = await axios.post(`${authBaseURL}/start`, {
            publicAddress: address
        });

        return nonceData;
    } catch (err) {
        console.log('[getNonce] Err', err);
        return null;
    }
};

export const verifyAddress = async (address: string, signature: string) => {
    try {
        const { data: verifyData } = await axios.post(`${authBaseURL}/verify`, {
            publicAddress: address,
            signature
        });

        return verifyData;
    } catch (err) {
        console.log('[verifyAddress] Err', err);
        return null;
    }
};
