import React, { FC } from "react";
import {
    Box,
    Typography,
    useTheme,
    alpha,
    Button,
    Link,
} from "@mui/material";
import { round } from "lodash";
import useTokenLiveData from "../../hooks/useTokenLiveData";
import PGXIcon from "../../assets/PGXIcon.png";
import VISIcon from "../../assets/VISIcon.png";

const PegaxyTokensInfo: FC = () => {
    const theme = useTheme();
    const { pgxPrice, visPrice } = useTokenLiveData();

    const formatter = (price: number): string => {
        if (price && price < 1) {
            const nums = price.toString().split('.')[1].split('');
            let count = 0;
            for (const num of nums) {
                if (parseInt(num) === 0) count += 1;
                else break;
            }
            count += 3;
            return round(price, count).toLocaleString('en', {
                maximumFractionDigits: count,
            });
        }
        return price.toLocaleString('en');
    };

    return (
        <Box
            color="neutral.200"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                backgroundColor: alpha(theme.palette.primary.light, 0.15),
            }}
        >
            <Box
                color="neutral.200"
                sx={{
                    display: 'flex',
                    paddingY: 1.5,
                    justifyContent: 'space-evenly',
                    width: '100%'
                }}
            >
                <Button
                    component={Link}
                    sx={{ display: 'flex', alignItems: 'center' }}
                    variant="text"
                    href="https://polygon-analytics.kyberswap.com/token/0xc1c93d475dc82fe72dbc7074d55f5a734f8ceeae"
                    target="_blank"
                    startIcon={
                        <div style={{
                            width: 20,
                            height: 20,
                            display: 'flex'
                        }}>
                            <img style={{ maxWidth: '100%' }} src={PGXIcon} alt="" />
                        </div>
                    }
                >
                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                        <Typography
                            variant="body1"
                            fontWeight="600"
                            color="neutral.200"
                        >
                            {formatter(pgxPrice)}

                        </Typography>
                        <Typography
                            fontSize="10px"
                            fontWeight="600"
                            color="neutral.400"
                            sx={{ ml: 0.3 }}
                            display='inline'
                        >
                            USD
                        </Typography>
                    </Box>
                </Button>
                <Button
                    component={Link}
                    sx={{ display: 'flex', alignItems: 'center' }}
                    variant="text"
                    href="https://polygon-analytics.kyberswap.com/token/0xcc1b9517460d8ae86fe576f614d091fca65a28fc"
                    target="_blank"
                    startIcon={
                        <div style={{
                            width: 20,
                            height: 20,
                            display: 'flex'
                        }}>
                            <img style={{ maxWidth: '100%' }} src={VISIcon} alt="" />
                        </div>
                    }
                >
                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                        <Typography
                            variant="body1"
                            fontWeight="600"
                            color="neutral.200"
                        >
                            {formatter(visPrice)}

                        </Typography>
                        <Typography
                            fontSize="10px"
                            fontWeight="600"
                            color="neutral.400"
                            sx={{ ml: 0.3 }}
                            display='inline'
                        >
                            USD
                        </Typography>
                    </Box>
                </Button>
            </Box>
            <Button
                component={Link}
                sx={{ mx: -2, borderRadius: 0 }}
                variant="contained"
                color="secondary"
                fullWidth
                href="https://kyberswap.com/#/swap"
                target="_blank"
            >
                Swap Now!
            </Button>
        </Box>
    )
}

export default PegaxyTokensInfo
