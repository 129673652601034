import { useEffect, useState } from "react";
import "./theme/webfonts.css";
import "./App.scss";
import { Helmet } from "react-helmet";
import { PageLayout } from "./components/layout/main/PageLayout";
import { Route, Routes } from "react-router";
import { OverviewPage } from "./pages/Metaverse/Overview/OverviewPage";
import { DashboardPage } from "./pages/Personal/Dashboard/DashboardPage";
import { AnalyticsPage } from "./pages/Metaverse/Analytics/AnalyticsPage";
import { PegaBreedingPage, PegaFusingPage } from "./pages/Pegas";
import { LeaderboardPage } from "./pages/Metaverse/Leaderboard/LeaderboardPage";
import { NoMatchPage } from "./pages/Errors/NoMatchPage";
import { PegaPage } from "./pages/Personal/Pega/PegaPage";
import { ValentineEventPage } from "./pages/Metaverse/Events/ValentineEvent/ValentineEventPage";
import usePageTracking from "./hooks/usePageTracking";
import { MaintenanceModeProvider } from "./contexts/maintenance-mode";

const getFaviconPath = (isDarkMode: boolean = false): string => {
  return `/favicon-${isDarkMode ? "dark" : "light"}.ico`;
};

function App() {
  const [faviconHref, setFaviconHref] = useState("/favicon-light.ico");

  usePageTracking();

  const changeFaviconTheme = (event: any) => {
    event.matches
        ? setFaviconHref(getFaviconPath(true))
        : setFaviconHref(getFaviconPath(false));
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window
          .matchMedia("(prefers-color-scheme: dark)")
          .addEventListener("change", changeFaviconTheme);
    }

    return () => {
      window
          .matchMedia("(prefers-color-scheme: dark)")
          .removeEventListener("change", changeFaviconTheme);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <div className="app-wrapper">
        <Helmet>
          <link rel="icon" type="image/png" href={faviconHref} />
        </Helmet>
        <PageLayout>
          <MaintenanceModeProvider>
            <Routes>
              <Route index element={<OverviewPage />} />
              <Route path="/personal/dashboard" element={<DashboardPage />} />
              <Route path="/personal/pega" element={<PegaPage />} />
              <Route path="/analytics" element={<AnalyticsPage />} />
              <Route path="/liquidity" element={<AnalyticsPage />} />
              <Route path="/pega-breeding" element={<PegaBreedingPage />} />
              <Route path="/pega-fusing" element={<PegaFusingPage />} />
              <Route path="/leaderboard" element={<LeaderboardPage />} />
              <Route path="/event/valentine" element={<ValentineEventPage />} />
              <Route path="*" element={<NoMatchPage />} />
            </Routes>
          </MaintenanceModeProvider>
        </PageLayout>
      </div>
  );
}

export default App;
