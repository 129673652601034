import {
  Box,
  Container,
  Grid,
  MenuItem,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import moment from "moment";
import shallow from "zustand/shallow";

import { VISMintBurnRatio } from "../../../components/metaverse/overview/VISMintBurnRatio";
import { PegaSalesSummary } from "../../../components/metaverse/overview/PegaSalesSummary/PegaSalesSummary";
import { PegaFloorPrices } from "../../../components/metaverse/overview/PegaFloorPrices/PegaFloorPrices";
import { VISSummary } from "../../../components/metaverse/overview/VISSummary";
import { Timeframe } from "../../../interfaces/timeframe";
import { useFetch } from "use-http";
import {
  timeframeToDate,
  timeframeToEpochSeconds,
} from "../../../utils/timeframeToDate";
import { GlobalRenterShares } from "../../../components/metaverse/overview/GlobalRenterShares";
import { usePreferenceStore } from "../../../stores/preferences";
import { useTheme } from "@mui/material/styles";
import useLiveClockUTC from "../../../hooks/useLiveClockUTC";

export const OverviewPage: FC = () => {
  const theme = useTheme();
  const { preferences, updatePreferences } = usePreferenceStore(
    ({ preferences, updatePreferences }) => ({
      preferences,
      updatePreferences,
    }),
    shallow
  );

  const liveClockUTC = useLiveClockUTC();

  const [salesVolumeData, setSalesVolumeData] = useState<any>({
    dataset: [],
    total: 0,
  });
  const [salesVolumeLabels, setSalesVolumeLabels] = useState<any>([]);
  const [totalSoldData, setTotalSoldData] = useState<any>({
    dataset: [],
    total: 0,
  });
  const [totalSoldLabels, setTotalSoldLabels] = useState<any>([]);
  const [populatingSummaryData, setPopulatingSummaryData] =
    useState<boolean>(false);

  const [visMintBurnRatioDatasets, setVisMintBurnRatioDatasets] = useState<any>(
    []
  );
  const [visMintBurnRatioLabels, setVisMintBurnRatioLabels] = useState<any>([]);
  const [totalVISBurned, setTotalVISBurned] = useState<number>(0);
  const [totalVISMinted, setTotalVISMinted] = useState<number>(0);
  const [netVISMintBurn, setNetVISMintBurn] = useState<number>(0);
  const [populatingVISMintBurnData, setPopulatingVISMintBurnData] =
    useState<boolean>(false);

  const [globalSharesData, setGlobalSharesData] = useState<any>({
    dataset: [],
    total: 0,
  });
  const [globalSharesLabels, setGlobalSharesLabels] = useState<any>([]);
  const [populatingGlobalSharesData, setPopulatingGlobalSharesData] =
    useState<boolean>(false);

  const {
    get: getVISData,
    loading: loadingVISData,
    error: errorVISData,
  } = useFetch(`${process.env.REACT_APP_BASE_API_URL}/historical/vis`, {
    loading: true,
    retries: 5,
    cacheLife: 1 * 60000, // 1 minutes
    persist: false,
  });

  const {
    get: getEarningsData,
    loading: loadingEarningsData,
    error: errorEarningsData,
  } = useFetch(`${process.env.REACT_APP_BASE_API_URL}/earnings`, {
    loading: true,
    retries: 5,
    cacheLife: 1 * 60000, // 1 minutes
    persist: false,
  });

  const {
    get: getAssetsData,
    loading: loadingAssetsData,
    error: errorAssetsData,
  } = useFetch(`${process.env.REACT_APP_BASE_API_URL}/assets/count`, {
    loading: true,
    retries: 5,
    cacheLife: 1 * 60000, // 1 minutes
    persist: false,
  });

  const {
    get: getBurnData,
    loading: loadingBurnData,
    error: errorBurnData,
  } = useFetch(`${process.env.REACT_APP_BASE_API_URL}/assets/burn`, {
    loading: true,
    retries: 5,
    cacheLife: 1 * 60000, // 1 minutes
    persist: false,
  });

  const {
    get: getSharesData,
    loading: loadingSharesData,
    error: errorSharesData,
  } = useFetch(`${process.env.REACT_APP_BASE_API_URL}/stats/rental`, {
    loading: true,
    retries: 5,
    cacheLife: 1 * 60000, // 1 minutes
    persist: false,
  });

  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  const populateDataVISMintBurnRatio = async () => {
    setPopulatingVISMintBurnData(true);

    const data = await getVISData(
      timeframeToEpochSeconds(preferences.timeframe)
        ? `?from=${timeframeToEpochSeconds(preferences.timeframe)}`
        : ""
    );

    let labelsArr = [];

    if (data.burned && data.minted) {
      const totalBurn = data.burned.reduce(
        (p: number, c: any) => p + c.amount,
        0
      );
      const totalMint = data.minted.reduce(
        (p: number, c: any) => p + c.amount,
        0
      );
      const netMintBurn = totalMint - totalBurn;
      const netMintBurnPercent = (netMintBurn / totalMint) * 100;

      setTotalVISBurned(Number(totalBurn.toFixed(0)));
      setTotalVISMinted(Number(totalMint.toFixed(0)));

      setNetVISMintBurn(netMintBurnPercent);

      for (let i = 0; i < data.burned.length; i++) {
        labelsArr.push(moment(data.burned[i].date).format("DD-MMM-YY"));
      }

      for (let i = 0; i < data.minted.length; i++) {
        labelsArr.push(moment(data.minted[i].date).format("DD-MMM-YY"));
      }

      setVisMintBurnRatioDatasets([
        {
          name: "Minted",
          data: data.minted.map((d: any) => Number(d.amount.toFixed(0))),
          backgroundColor: "rgb(53, 162, 235)",
        },
        {
          name: "Burned",
          data: data.burned.map((d: any) => Number(d.amount.toFixed(0))),
          backgroundColor: "rgb(255, 99, 132)",
        },
      ]);
    }

    // @ts-ignore
    setVisMintBurnRatioLabels([...new Set(labelsArr)]);

    setPopulatingVISMintBurnData(false);
  };

  const populateSummaryData = async () => {
    setPopulatingSummaryData(true);
    const earningsData = await getEarningsData(
      `/historical/user${timeframeToEpochSeconds(preferences.timeframe)
        ? "?since=" + timeframeToEpochSeconds(preferences.timeframe)
        : ""
      }`
    );
    const assetsData = await getAssetsData(
      `/historical${timeframeToEpochSeconds(preferences.timeframe)
        ? "?since=" + timeframeToEpochSeconds(preferences.timeframe)
        : ""
      }`
    );
    const burnData = await getBurnData(
      `/historical${timeframeToEpochSeconds(preferences.timeframe)
        ? "?since=" + timeframeToEpochSeconds(preferences.timeframe)
        : ""
      }`
    );
    setPopulatingSummaryData(false);

    let earningsLabelsArr: Array<any> = [];
    let assetsLabelsArr: Array<any> = [];
    let burnedLabelsArr: Array<any> = [];

    let totalPopulationDataset = [];
    let totalBurnedDataset = [];
    let salesVolumeDataset = [];
    let totalSoldDataset = [];

    let totalSold = earningsData?.reduce(
      (p: number, c: any) => p + c.totalPegaSellCount,
      0
    );
    let totalSalesVolume = earningsData?.reduce(
      (p: number, c: any) => p + Number(c.totalPegaSellUSDT.toFixed(0)),
      0
    );

    for (let i = 0; i < earningsData.length; i++) {
      earningsLabelsArr.push(
        moment.unix(earningsData[i].epoch).format("DD-MMM-YY")
      );
      salesVolumeDataset.push(earningsData[i].totalPegaSellUSDT);
      totalSoldDataset.push(earningsData[i].totalPegaSellCount);
    }

    for (let i = 0; i < assetsData.length; i++) {
      assetsLabelsArr.push(
        moment.unix(assetsData[i].epoch).format("DD-MMM-YY")
      );
      totalPopulationDataset.push(assetsData[i].pega);
    }

    for (let i = 0; i < burnData.length; i++) {
      burnedLabelsArr.push(moment.unix(burnData[i].epoch).format("DD-MMM-YY"));
      totalBurnedDataset.push(burnData[i].pegaBurned);
    }

    const uniqueEarningsLabels = new Set(earningsLabelsArr);
    // @ts-ignore
    setSalesVolumeLabels([...uniqueEarningsLabels]);
    // @ts-ignore
    setTotalSoldLabels([...uniqueEarningsLabels]);

    setSalesVolumeData({
      dataset: [
        {
          name: "Sales Volume (USDT)",
          data: salesVolumeDataset,
        },
      ],
      total: totalSalesVolume,
    });

    setTotalSoldData({
      dataset: [
        {
          name: "Total Sold",
          data: totalSoldDataset,
          backgroundColor: "rgb(53, 162, 235)",
        },
      ],
      total: totalSold,
    });
  };

  const populateGlobalSharesData = async () => {
    setPopulatingGlobalSharesData(true);
    const data = await getSharesData(`/distribution`);

    setGlobalSharesLabels(data.map((item: any) => item.distribution));

    setGlobalSharesData(
      data.map((item: any) => ({
        name: item.distribution,
        data: [item.count],
      }))
    );

    setPopulatingGlobalSharesData(false);
  };

  useEffect(() => {
    populateSummaryData();
    populateDataVISMintBurnRatio();
    populateGlobalSharesData();
  }, [preferences.timeframe]);

  return (
    <>
      <Helmet>
        <title>Overview | Pegaxy Apollo</title>
      </Helmet>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 6,
        }}
      >
        <Container maxWidth={"xl"}>
          <Box sx={{ mb: 4 }}>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
              sx={{ px: 2 }}
            >
              <Grid item>
                <Typography variant="h4">Overview</Typography>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  m: -1,
                }}
              >
                {!isSm ? (
                  <Typography
                    variant="caption"
                    sx={{
                      color: theme.palette.text.primary,
                    }}
                  >
                    {`(UTC - ${liveClockUTC})`}
                  </Typography>
                ) : null}
                {preferences.timeframe &&
                  <TextField
                    value={preferences.timeframe}
                    label="Period"
                    select
                    size="small"
                    sx={{ m: 1, minWidth: 200 }}
                    onChange={(e) => {
                      const timeframe: Timeframe = e.target.value as Timeframe;
                      updatePreferences({
                        ...preferences,
                        timeframe: timeframe,
                      });
                    }}
                  >
                    <MenuItem value="today">Today</MenuItem>
                    <MenuItem value="yesterday">Today + Yesterday</MenuItem>
                    <MenuItem value="week">Last 7 days</MenuItem>
                    <MenuItem value="biweek">Last 15 days</MenuItem>
                    <MenuItem value="month">Last month</MenuItem>
                    <MenuItem value="all">All</MenuItem>
                  </TextField>
                }
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <PegaSalesSummary
                  salesVolumeData={salesVolumeData}
                  salesVolumeLabels={salesVolumeLabels}
                  totalSoldData={totalSoldData}
                  totalSoldLabels={totalSoldLabels}
                  loading={
                    loadingEarningsData ||
                    loadingAssetsData ||
                    loadingBurnData ||
                    populatingSummaryData
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <VISMintBurnRatio
                  datasets={visMintBurnRatioDatasets}
                  labels={visMintBurnRatioLabels}
                  loading={loadingVISData || populatingVISMintBurnData}
                />
              </Grid>
              <Grid xs={12} item>
                <VISSummary
                  totalVISBurned={totalVISBurned}
                  totalVISMinted={totalVISMinted}
                  netVISMintBurn={netVISMintBurn}
                  loading={loadingVISData || populatingVISMintBurnData}
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalRenterShares
                  data={globalSharesData}
                  labels={globalSharesLabels}
                  loading={loadingSharesData || populatingGlobalSharesData}
                />
              </Grid>
              <Grid item xs={12}>
                <PegaFloorPrices timeframe={preferences.timeframe} />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};
