import React, { FC, useEffect, useState } from "react";
import { alpha, Avatar, Box, Button, Tooltip, Typography } from "@mui/material";
import { DataTable } from "../../../data-table/DataTable";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import shallow from "zustand/shallow";

import { Loading } from "../../../utils/Loading";
import {
  GameServices,
  getFriendlyServiceName,
  RentModes,
} from "../../../../interfaces/game-services";
import { PegaBloodLine } from "../../../../interfaces/pega";
import {
  humanizerOptions,
  shortEnglishHumanizer,
} from "../../../../utils/humanizer";
import { copyTextToClipboard } from "../../../../utils/copyToClipboard";
import ReactCardFlip from "react-card-flip";
import { usePreferenceStore } from "../../../../stores/preferences";

const BloodLineSortingDict = {
  Hoz: 1,
  Campona: 2,
  Klin: 3,
  Zan: 4,
};

const BreedTypeSortingDict = {
  Founding: 1,
  Legendary: 2,
  Epic: 3,
  Rare: 4,
  Pacer: 5,
};

export interface PegaTableData {
  bloodLine: PegaBloodLine;
  bornTime: number;
  breedCount: number;
  breedCooldownMillis?: number;
  breedType: string;
  bronze: number;
  canBreedAt: number;
  canRaceAt: number;
  energy: number;
  fatherId: number;
  fire: number;
  gender: string;
  gold: number;
  id: number;
  lastBreedTime: number;
  lastReduceEnergy: number;
  lastRenterAddress: string | null;
  lastRenterIsDirect: boolean;
  lastRenterPrice: number;
  lastRenterRentAt: number;
  lastRenterRentDuration: number;
  lastRenterRentMode: string;
  lightning: number;
  lose: number;
  motherId: number;
  name: string;
  ownerAddress: string;
  ownerPegaRewards: string;
  raceCooldownMillis?: number;
  rentTimeEnd: number;
  renterAddress: string;
  renterPegaRewards: number;
  service: GameServices;
  silver: number;
  speed: number;
  strength: number;
  totalRaces: number;
  totalVISEarned: number;
  water: number;
  win: number;
  winRate: number;
  wind: number;
}

interface PegaTableProps {
  tableData: Array<PegaTableData> | Array<never>;
  loading: boolean;
}

export const PegaTable: FC<PegaTableProps> = ({ tableData, loading }) => {
  const [pegaFilter, setPegaFilter] = useState<string>("all");
  const preferences = usePreferenceStore((state) => state.preferences, shallow);

  const [displayedTableData, setDisplayedTableData] = useState<
    Array<PegaTableData> | Array<never>
  >([]);

  const handlePegaFilterChange = (newFilter: string) => {
    switch (newFilter) {
      case "idle": {
        setPegaFilter("idle");
        return setDisplayedTableData(
          tableData.filter(
            (row: PegaTableData) => row.service === GameServices.Resting
          )
        );
      }
      case "rented": {
        setPegaFilter("rented");
        return setDisplayedTableData(
          tableData.filter(
            (row: PegaTableData) => row.service === GameServices.Renting
          )
        );
      }
      case "breed": {
        setPegaFilter("breed");
        return setDisplayedTableData(
          tableData.filter(
            (row: PegaTableData) => row.breedCooldownMillis === 0
          )
        );
      }
      case "all": {
        setPegaFilter("all");
        return setDisplayedTableData(tableData);
      }
    }
  };

  useEffect(() => {
    setDisplayedTableData(tableData);
  }, [tableData]);

  const statsTableColumns = React.useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: ({ row }: { row: any }) => (
          <Button {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <RemoveIcon /> : <AddIcon />}
          </Button>
        ),
        width: 20,
      },
      {
        Header: "Name",
        accessor: "name",
        sortable: true,
        Cell: ({ row }: { row: any }) => (
          <Tooltip title={"Open in New Window"} placement={"top"}>
            <Button
              color={"inherit"}
              startIcon={<OpenInNewIcon />}
              component={"a"}
              href={`https://play.pegaxy.io/my-assets/pega/${row.original.id}`}
              target={"_blank"}
              rel={"noreferrer"}
            >
              {row.values.name}
            </Button>
          </Tooltip>
        ),
      },
      {
        Header: "Win %",
        accessor: "winRate",
        sortable: true,
        Cell: ({ row }: any) => {
          return (
            <Typography variant={"body2"} sx={{}}>
              {(row.values.winRate * 100).toFixed(0) + "%"}
            </Typography>
          );
        },
        sortType: "number",
        maxWidth: 200,
      },
      {
        Header: "Type",
        accessor: "breedType",
        sortable: true,
        sortType: (rowA: any, rowB: any) => {
          // @ts-ignore
          return BreedTypeSortingDict[rowA.values.breedType] >
            // @ts-ignore
            BreedTypeSortingDict[rowB.values.breedType]
            ? -1
            : 1;
        },
      },
      {
        Header: "Bloodline",
        accessor: "bloodLine",
        show: true,
        sortable: true,
        sortType: (rowA: any, rowB: any, columnId: number, desc: boolean) => {
          // @ts-ignore
          return BloodLineSortingDict[rowA.values.bloodLine] >
            // @ts-ignore
            BloodLineSortingDict[rowB.values.bloodLine]
            ? -1
            : 1;
        },
      },
      {
        Header: "# Breed",
        accessor: "breedCount",
        sortable: true,
        Cell: ({ row }: any) => {
          return (
            <Typography variant={"body2"} sx={{}}>
              {row.values.breedCount}
            </Typography>
          );
        },
        maxWidth: "100%",
      },
      {
        Header: "Speed",
        accessor: "speed",
        sortable: true,
        Cell: ({ row }: any) => {
          return (
            <Typography variant={"body2"} sx={{}}>
              {row.values.speed.toFixed(0)}
            </Typography>
          );
        },
        sortType: "number",
      },
      {
        Header: "Strength",
        accessor: "strength",
        sortable: true,
        Cell: ({ row }: any) => {
          return (
            <Typography variant={"body2"} sx={{}}>
              {row.values.strength.toFixed(0)}
            </Typography>
          );
        },
        sortType: "number",
      },
      {
        Header: "Lightning",
        accessor: "lightning",
        sortable: true,
        Cell: ({ row }: any) => {
          return (
            <Typography variant={"body2"} sx={{}}>
              {row.values.lightning.toFixed(0)}
            </Typography>
          );
        },
        sortType: "number",
      },
      {
        Header: "Wind",
        accessor: "wind",
        sortable: true,
        Cell: ({ row }: any) => {
          return (
            <Typography variant={"body2"} sx={{}}>
              {row.values.wind.toFixed(0)}
            </Typography>
          );
        },
        sortType: "number",
      },
      {
        Header: "Water",
        accessor: "water",
        sortable: true,
        Cell: ({ row }: any) => {
          return (
            <Typography variant={"body2"} sx={{}}>
              {row.values.water.toFixed(0)}
            </Typography>
          );
        },
        sortType: "number",
      },
      {
        Header: "Fire",
        accessor: "fire",
        sortable: true,
        Cell: ({ row }: any) => {
          return (
            <Typography variant={"body2"} sx={{}}>
              {row.values.fire.toFixed(0)}
            </Typography>
          );
        },
        sortType: "number",
      },
      {
        Header: "",
        accessor: "gender",
        sortable: true,
        show: false,
      },
      {
        Header: "",
        accessor: "energy",
        sortable: true,
        show: false,
      },
      {
        Header: "",
        accessor: "breedCooldownMillis",
        sortable: true,
        show: false,
      },
      {
        Header: "",
        accessor: "raceCooldownMillis",
        sortable: true,
        show: false,
      },
      {
        Header: "",
        accessor: "service",
        sortable: true,
        show: false,
      },
      {
        Header: "",
        accessor: "lastRenterPrice",
        sortable: true,
        show: false,
      },
      {
        Header: "",
        accessor: "lastRenterAddress",
        sortable: true,
        show: false,
      },
    ],
    []
  );

  const regularTableColumns = React.useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: ({ row }: { row: any }) => (
          <Button {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <RemoveIcon /> : <AddIcon />}
          </Button>
        ),
        width: 20,
      },
      {
        Header: "Name",
        accessor: "name",
        sortable: true,
        Cell: ({ row }: { row: any }) => (
          <Tooltip title={"Open in New Window"} placement={"top"}>
            <Button
              color={"inherit"}
              startIcon={<OpenInNewIcon />}
              component={"a"}
              href={`https://play.pegaxy.io/my-assets/pega/${row.original.id}`}
              target={"_blank"}
              rel={"noreferrer"}
            >
              {row.values.name}
            </Button>
          </Tooltip>
        ),
      },
      {
        Header: "Gender",
        accessor: "gender",
        sortable: true,
        maxWidth: "100%",
      },
      {
        Header: "Energy",
        accessor: "energy",
        sortable: true,
        maxWidth: "100%",
        Cell: ({ row }: any) => {
          return (
            <Typography variant={"body2"} sx={{}}>
              {row.values.energy}
            </Typography>
          );
        },
      },
      {
        Header: "# Breed",
        accessor: "breedCount",
        sortable: true,
        Cell: ({ row }: any) => {
          return (
            <Typography variant={"body2"} sx={{}}>
              {row.values.breedCount}
            </Typography>
          );
        },
        maxWidth: "100%",
      },
      {
        Header: "Breed Type",
        accessor: "breedType",
        sortable: true,
        show: false,
        sortType: (rowA: any, rowB: any) => {
          // @ts-ignore
          return BreedTypeSortingDict[rowA.values.breedType] >
            // @ts-ignore
            BreedTypeSortingDict[rowB.values.breedType]
            ? -1
            : 1;
        },
      },
      {
        Header: "Bloodline",
        accessor: "bloodLine",
        show: true,
        sortable: true,
        sortType: (rowA: any, rowB: any, columnId: number, desc: boolean) => {
          // @ts-ignore
          return BloodLineSortingDict[rowA.values.bloodLine] >
            // @ts-ignore
            BloodLineSortingDict[rowB.values.bloodLine]
            ? -1
            : 1;
        },
      },
      {
        Header: () => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                width: 20,
                height: 20,
                mr: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <AccessTimeIcon sx={{ maxWidth: "100%" }} />
            </Box>
            <Typography variant="overline" sx={{ mt: 0.4 }}>
              Breed
            </Typography>
          </Box>
        ),
        accessor: "breedCooldownMillis",
        sortable: true,
        Cell: ({ row }: { row: any }) => {
          return row.values.breedCooldownMillis === 0
            ? "Ready"
            : shortEnglishHumanizer(
                row.values.breedCooldownMillis,
                humanizerOptions
              );
        },
      },
      {
        Header: () => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                width: 20,
                height: 20,
                mr: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <AccessTimeIcon sx={{ maxWidth: "100%" }} />
            </Box>
            <Typography variant="overline" sx={{ mt: 0.4 }}>
              Race
            </Typography>
          </Box>
        ),
        accessor: "raceCooldownMillis",
        show: true,
        sortable: true,
        Cell: ({ row }: { row: any }) => {
          return row.values.raceCooldownMillis === 0
            ? "Ready"
            : shortEnglishHumanizer(
                row.values.raceCooldownMillis,
                humanizerOptions
              );
        },
      },
      {
        Header: "Status",
        accessor: "service",
        sortable: true,
        Cell: ({ row }: { row: any }) => {
          return getFriendlyServiceName(row.values.service);
        },
      },
      {
        Header: "Last Renting Share",
        accessor: "lastRenterPrice",
        sortable: true,
        Cell: ({ row }: { row: any }) => {
          return `${
            row.original.lastRenterRentMode === RentModes.ShareProfit
              ? `${100 - row.original.lastRenterPrice}% - ${
                  row.original.lastRenterPrice
                }%`
              : `${row.original.lastRenterPrice} PGX`
          }`;
        },
      },
      {
        Header: "Last Renter",
        accessor: "lastRenterAddress",
        sortable: true,
        Cell: ({ row }: { row: any }) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            {row.values.lastRenterAddress ? (
              <>
                <Tooltip title={"Copy Address"} placement={"top"}>
                  <Button
                    color={"inherit"}
                    endIcon={<ContentCopyIcon fontSize={"small"} />}
                    sx={{ mr: 1 }}
                    onClick={() =>
                      copyTextToClipboard(row.values.lastRenterAddress)
                    }
                  >
                    {`${row.values.lastRenterAddress.substring(
                      0,
                      5
                    )}...${row.values.lastRenterAddress.substring(
                      row.values.lastRenterAddress.length - 5
                    )}`}
                  </Button>
                </Tooltip>
              </>
            ) : (
              ""
            )}
          </Box>
        ),
      },
      {
        Header: "",
        accessor: "winRate",
        show: false,
        sortType: "number",
      },
      {
        Header: "",
        accessor: "strength",
        show: false,
        sortType: "number",
      },
      {
        Header: "",
        accessor: "speed",
        show: false,
        sortType: "number",
      },
      {
        Header: "",
        accessor: "lightning",
        show: false,
        sortType: "number",
      },
      {
        Header: "",
        accessor: "wind",
        show: false,
        sortType: "number",
      },
      {
        Header: "",
        accessor: "water",
        show: false,
        sortType: "number",
      },
      {
        Header: "",
        accessor: "fire",
        show: false,
        sortType: "number",
      },
    ],
    []
  );

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <DataTable
          data={displayedTableData}
          columns={
            preferences.pegaTableView === "stats"
              ? statsTableColumns
              : regularTableColumns
          }
          onSearchTermChange={() => {}}
          defaultSortBy={"name"}
          defaultSortDir={"asc"}
          manualPagination={false}
          manualSortBy={false}
          currentCustomFilter={pegaFilter}
          onCustomFilterChange={handlePegaFilterChange}
        />
      )}
    </>
  );
};
