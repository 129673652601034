import {Box, Container, Grid, Typography} from "@mui/material";
import React, {FC} from "react";
import {Helmet} from "react-helmet";
import {
    LeaderboardTopLiquidityProviders
} from "../../../components/metaverse/leaderboard/LeaderboardTopLiquidityProviders";


export const AnalyticsPage: FC = () => {
    return (
        <>
            <Helmet>
                <title>Liquidity Pool | Pegaxy Apollo</title>
            </Helmet>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 6
                }}
            >
                <Container maxWidth={"xl"}>
                    <Box sx={{ mb: 4 }}>
                        <Grid
                            container
                            justifyContent="space-between"
                            spacing={3}
                        >
                            <Grid item>
                                <Typography variant="h4">
                                    Liquidity Pool
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid
                        container
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <LeaderboardTopLiquidityProviders />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}
