// @ts-nocheck
import React from "react";
import {
  useAsyncDebounce,
  useExpanded,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  Box,
  Card,
  TextField,
  Table as MaterialUiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  Pagination,
  MenuItem,
  Grid,
} from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import shallow from "zustand/shallow";

import { EmptyResults } from "./EmptyResults";
import { Scrollbar } from "../layout/scrollbar/Scrollbar";
import { matchSorter } from "match-sorter";
import { VISIcon } from "../layout/icons/vis-icon";
import { usePreferenceStore } from "../../stores/preferences";

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = (val) => !val;

function GlobalFilter({ globalFilter, setGlobalFilter }) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <TextField
      size={"small"}
      fullWidth
      label={"Search"}
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      variant="outlined"
    />
  );
}

export const DataTable = ({
  data,
  columns,
  loading = false,
  onSearchTermChange,
  defaultSortBy,
  defaultSortDir,
  manualPagination = true,
  manualSortBy = true,
  currentCustomFilter = "all",
  onCustomFilterChange = (newFilter: string) => {},
}) => {
  const defaultSort =
    defaultSortBy && defaultSortDir
      ? [{ id: defaultSortBy, desc: defaultSortDir === "desc" }]
      : [];

  const { preferences, updatePreferences } = usePreferenceStore(
    ({ preferences, updatePreferences }) => ({
      preferences,
      updatePreferences,
    }),
    shallow
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    pageCount,
    gotoPage,
    visibleColumns,
    getToggleHideAllColumnsProps,
    allColumns,
    setPageSize,
    state: { pageIndex, pageSize, sortBy, globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: preferences.pegaTableSize,
        pageIndex: 0,
        sortBy: defaultSort,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
        }),
      },
      manualPagination: manualPagination,
      manualSortBy: manualSortBy,
      // disableMultiSort: true,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <Box sx={{ p: 2 }}>
        <Grid container>
          <Grid
            md={4}
            sm={12}
            xs={12}
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {row.original.totalRaces === 0 ? (
              <>
                <Typography
                  color={"primary"}
                  variant={"overline"}
                  sx={{ display: "block", mb: 2 }}
                >
                  No races yet!
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  color={"primary"}
                  variant={"overline"}
                  sx={{ display: "block", mb: 2 }}
                >
                  Top 3 since stat-based era (out of {row.original.totalRaces}{" "}
                  races)
                </Typography>

                <Box>
                  <Typography variant={"overline"} sx={{ display: "block" }}>
                    Gold: {row.original.gold} -{" "}
                    {(
                      (row.original.gold / row.original.totalRaces) *
                      100
                    )?.toFixed(0)}
                    %
                  </Typography>
                  <Typography variant={"overline"} sx={{ display: "block" }}>
                    Silver: {row.original.silver} -{" "}
                    {(
                      (row.original.silver / row.original.totalRaces) *
                      100
                    )?.toFixed(0)}
                    %
                  </Typography>
                  <Typography variant={"overline"} sx={{ display: "block" }}>
                    Bronze: {row.original.bronze} -{" "}
                    {(
                      (row.original.bronze / row.original.totalRaces) *
                      100
                    )?.toFixed(0)}
                    %
                  </Typography>
                </Box>

                <Typography
                  color={"secondary"}
                  variant={"overline"}
                  sx={{ display: "block", mt: 2 }}
                >
                  Win Rate: {(row.original.winRate * 100).toFixed(0) + "%"}
                </Typography>
              </>
            )}
          </Grid>
          <Grid
            md={4}
            sm={12}
            xs={12}
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Typography
              color={"primary"}
              variant={"overline"}
              sx={{ display: "block", mb: 2 }}
            >
              VIS Earned (since stat-based era)
            </Typography>

            <Box>
              <Typography variant={"overline"} sx={{ display: "block" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <span>
                    Owner:{" "}
                    {row.original.ownerPegaRewards
                      ? row.original.ownerPegaRewards.toLocaleString()
                      : "N/A"}
                  </span>
                  <Box sx={{ width: 20, height: 20, ml: 1, mb: 0.4 }}>
                    <VISIcon />
                  </Box>
                </Box>
              </Typography>
              <Typography variant={"overline"} sx={{ display: "block" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <span>
                    Renter(s):{" "}
                    {row.original.renterPegaRewards
                      ? row.original.renterPegaRewards.toLocaleString()
                      : "N/A"}
                  </span>
                  <Box sx={{ width: 20, height: 20, ml: 1, mb: 0.4 }}>
                    <VISIcon />
                  </Box>
                </Box>
              </Typography>
            </Box>

            <Typography
              color={"secondary"}
              variant={"overline"}
              sx={{ display: "block", mt: 2 }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <span>
                  Total:{" "}
                  {row.original.totalVISEarned
                    ? row.original.totalVISEarned.toLocaleString()
                    : "N/A"}
                </span>
                <Box sx={{ width: 20, height: 20, ml: 1, mb: 0.4 }}>
                  <VISIcon />
                </Box>
              </Box>
            </Typography>
          </Grid>
          <Grid md={4} sm={12} xs={12} item>
            <Typography
              color={"primary"}
              variant={"overline"}
              sx={{ display: "block", mb: 2 }}
            >
              Info
            </Typography>

            <Typography variant={"overline"} sx={{ display: "block" }}>
              ID: {row.original.id}
            </Typography>
            <Typography variant={"overline"} sx={{ display: "block" }}>
              Breed Type: {row.original.breedType}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    ),
    []
  );

  return (
    <Box>
      <Card sx={{ boxShadow: 10, mb: 1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
            flexWrap: "wrap",
            mt: 1,
            mb: 1,
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: { md: "auto", sm: "100%", xs: "100%" },
              mb: { md: 0, sm: 2, xs: 2 },
            }}
          >
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              ml: { md: "auto", sm: 0, xs: 0 },
            }}
          >
            <Button
              color={"primary"}
              variant={currentCustomFilter === "all" ? "contained" : "outlined"}
              sx={{
                mr: 2,
              }}
              onClick={() => {
                onCustomFilterChange("all");
              }}
            >
              {"All"}
            </Button>
            <Button
              color={"primary"}
              variant={
                currentCustomFilter === "rented" ? "contained" : "outlined"
              }
              sx={{
                mr: 2,
              }}
              onClick={() => {
                onCustomFilterChange("rented");
              }}
            >
              {"Rented Pega"}
            </Button>
            <Button
              color={"primary"}
              variant={
                currentCustomFilter === "breed" ? "contained" : "outlined"
              }
              sx={{
                mr: 2,
              }}
              onClick={() => {
                onCustomFilterChange("breed");
              }}
            >
              {"Ready to Breed"}
            </Button>
            <Button
              color={"primary"}
              variant={
                currentCustomFilter === "idle" ? "contained" : "outlined"
              }
              sx={{
                mr: 2,
              }}
              onClick={() => {
                onCustomFilterChange("idle");
              }}
            >
              {"Idle Pega"}
            </Button>
          </Box>
        </Box>
      </Card>
      {/*<Card sx={{ boxShadow: 10, mb: 1, p: 2 }}>*/}
      {/*    <Box sx={{ display: 'flex', flexWrap: "wrap" }}>*/}
      {/*        <FormControlLabel*/}
      {/*            label={*/}
      {/*                <Typography variant="overline">*/}
      {/*                    {"All"}*/}
      {/*                </Typography>*/}
      {/*            }*/}
      {/*            control={*/}
      {/*                <Checkbox*/}
      {/*                    {...getToggleHideAllColumnsProps()}*/}
      {/*                />*/}
      {/*            }*/}
      {/*        />*/}
      {/*    {*/}
      {/*        allColumns.map(column => (*/}
      {/*            <FormControlLabel*/}
      {/*                key={column.id}*/}
      {/*                label={*/}
      {/*                    <Typography variant="overline">*/}
      {/*                        {column.Header}*/}
      {/*                    </Typography>*/}
      {/*                }*/}
      {/*                control={<Checkbox {...column.getToggleHiddenProps()} />}*/}
      {/*            />*/}
      {/*        ))*/}
      {/*    }*/}
      {/*    </Box>*/}
      {/*</Card>*/}
      <Card sx={{ boxShadow: 10 }}>
        {data.length > 0 ? (
          <Scrollbar>
            <MaterialUiTable>
              <TableHead sx={{ backgroundColor: "#5048e512" }}>
                {headerGroups.map((headerGroup) => (
                  <TableRow
                    key={headerGroup.id}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column: any) => {
                      if (column.sortable) {
                        return (
                          <TableCell
                            key={column.name}
                            sx={{
                              maxWidth: column.maxWidth
                                ? column.maxWidth
                                : "auto",
                            }}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <span>{column.render("Header")}</span>
                              <span style={{ marginLeft: 6 }}>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <ArrowDownwardIcon
                                      sx={{ width: 15, height: 15 }}
                                    />
                                  ) : (
                                    <ArrowUpwardIcon
                                      sx={{ width: 15, height: 15 }}
                                    />
                                  )
                                ) : (
                                  <SortIcon sx={{ width: 15, height: 15 }} />
                                )}
                              </span>
                            </div>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell
                            key={column.name}
                            sx={{
                              maxWidth: column.maxWidth
                                ? column.maxWidth
                                : "auto",
                            }}
                            {...column.getHeaderProps()}
                          >
                            <span>{column.render("Header")}</span>
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {page.map((row: any) => {
                  prepareRow(row);
                  return (
                    <React.Fragment key={row.id}>
                      <TableRow {...row.getRowProps()}>
                        {row.cells.map((cell: any) => {
                          return (
                            <TableCell
                              sx={{
                                maxWidth: cell.column.maxWidth
                                  ? cell.column.maxWidth
                                  : "auto",
                              }}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </TableCell>
                          );
                        })}
                      </TableRow>

                      {row.isExpanded ? (
                        <TableRow>
                          <TableCell colSpan={visibleColumns.length}>
                            {renderRowSubComponent({ row })}
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </MaterialUiTable>
          </Scrollbar>
        ) : (
          <EmptyResults />
        )}
      </Card>
      {page.length > 1 ? (
        <Card sx={{ boxShadow: 10, mt: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "space-between",
              },
              alignItems: "center",
              p: 2,
              flexWrap: "wrap",
            }}
          >
            <Box>
              <Typography variant="overline">{`Showing ${
                data.length >= pageSize ? pageSize : data.length
              } of ${data.length} Pega`}</Typography>
            </Box>
            <Box>
              {pageOptions.length > 1 ? (
                <Pagination
                  color={"primary"}
                  count={pageOptions.length}
                  page={pageIndex + 1}
                  onChange={(e, value) => {
                    const page = value ? Number(value) - 1 : 0;
                    gotoPage(page);
                  }}
                />
              ) : null}
            </Box>
            <Box>
              <TextField
                value={pageSize}
                select
                size="small"
                sx={{ m: 1, minWidth: 200 }}
                onChange={(e) => {
                  updatePreferences({
                    ...preferences,
                    pegaTableSize: e.target.value,
                  });

                  setPageSize(e.target.value);
                }}
              >
                {[10, 20, 30, 40, 50].map((option: number, idx: number) => {
                  return (
                    <MenuItem key={idx} value={option}>
                      Show {option} Pega
                    </MenuItem>
                  );
                })}
              </TextField>
            </Box>
          </Box>
        </Card>
      ) : null}
    </Box>
  );
};
