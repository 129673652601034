import { useEffect, useState } from "react";
import axios from "axios";

enum BreedType {
    Founding = "Founding",
    Legendary = "Legendary",
    Epic = "Epic",
    Rare = "Rare",
    Pacer = "Pacer"
}

interface BurnedBreedTypeBreakdown {
    breedType: BreedType;
    pegaBurned: number;
}

interface BurnedBreedTypeBreakdownResult {
    pacer: number;
    rare: number;
    epic: number;
    legendary: number;
    founding: number;
}

interface BreedTypeDistribution {
    breedType: BreedType;
    count: number;
}

interface PegaPopulationBurnedResult {
    pacer: number,
    rare: number,
    epic: number,
    legendary: number,
    founding: number,
}

export default function useBurnedPegaData() {
    const [burnedPacer, setBurnedPacer] = useState<number>(0);
    const [burnedRare, setBurnedRare] = useState<number>(0);
    const [burnedEpic, setBurnedEpic] = useState<number>(0);
    const [burnedLegendary, setBurnedLegendary] = useState<number>(0);
    const [burnedFounding, setBurnedFounding] = useState<number>(0);

    const [totalVISBurned, setTotalVISBurned] = useState<number>(0);
    const [totalPGXSpent, setTotalPGXSpent] = useState<number>(0);
    const [totalPegaBurned, setTotalPegaBurned] = useState<number>(0);

    const [totalVISBurnedBreed, setTotalVISBurnedBreed] = useState<number>(0);
    const [totalPGXSpentBreed, setTotalPGXSpentBreed] = useState<number>(0);

    const [populationData, setPopulationData] = useState<PegaPopulationBurnedResult | null>(null)

    const [loadingBurnedBreakdown, setLoadingBurnedBreakdown] = useState<boolean>(true);
    const [loadingPopulationData, setLoadingPopulationData] = useState<boolean>(true);
    const [loadingSpentData, setLoadingSpentData] = useState<boolean>(true);
    const [loadingSpentBreedData, setLoadingSpentBreedData] = useState<boolean>(true);

    const getBurnedBreakdown = async (): Promise<BurnedBreedTypeBreakdownResult | null> => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/assets/burn/breakdown`)
            if (data && data.length > 0) {
                return {
                    pacer: data.find((i: BurnedBreedTypeBreakdown) => i.breedType === BreedType.Pacer)?.count || 0,
                    rare: data.find((i: BurnedBreedTypeBreakdown) => i.breedType === BreedType.Rare)?.count || 0,
                    epic: data.find((i: BurnedBreedTypeBreakdown) => i.breedType === BreedType.Epic)?.count || 0,
                    legendary: data.find((i: BurnedBreedTypeBreakdown) => i.breedType === BreedType.Legendary)?.count || 0,
                    founding: data.find((i: BurnedBreedTypeBreakdown) => i.breedType === BreedType.Founding)?.count || 0,
                }
            }
        } catch (err) {
            console.log('[burnedBreakdown] Error Getting Burned Breakdown');
        }

        return null;
    }

    const getSpentData = async (): Promise<{ visBurned: number, pgxSpent: number } | null> => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/assets/burn/spent`)
            if (data) {
                return data
            }
        } catch (err) {
            console.log('[burnedBreakdown] Error Getting VIS Burned');
        }

        return null;
    }

    const getSpentBreedData = async (): Promise<{ visBurned: number, pgxSpent: number } | null> => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/assets/breed/spent`)
            if (data) {
                return data
            }
        } catch (err) {
            console.log('[burnedBreakdown] Error Getting VIS Burned');
        }

        return null;
    }

    const getPopulationData = async (): Promise<PegaPopulationBurnedResult | null> => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/pegas/distribution/breed-type`)
            if (data) {
                const totalPacer = data.find((i: BurnedBreedTypeBreakdown) => i.breedType === BreedType.Pacer)?.count || 0;
                const totalRare = data.find((i: BurnedBreedTypeBreakdown) => i.breedType === BreedType.Rare)?.count || 0;
                const totalEpic = data.find((i: BurnedBreedTypeBreakdown) => i.breedType === BreedType.Epic)?.count || 0;
                const totalLegendary = data.find((i: BurnedBreedTypeBreakdown) => i.breedType === BreedType.Legendary)?.count || 0;
                const totalFounding = data.find((i: BurnedBreedTypeBreakdown) => i.breedType === BreedType.Founding)?.count || 0;
                return {
                    pacer: Number(totalPacer),
                    rare: Number(totalRare),
                    epic: Number(totalEpic),
                    legendary: Number(totalLegendary),
                    founding: Number(totalFounding),
                }
            }
        } catch (err) {
            console.log('[burnedBreakdown] Error Getting VIS Burned');
        }

        return null;
    }

    async function getBurnedBreakdownFromAPI() {
        setLoadingBurnedBreakdown(true)

        const burnedBreakdown: BurnedBreedTypeBreakdownResult | null = await getBurnedBreakdown();

        if (burnedBreakdown) {
            setBurnedPacer(burnedBreakdown.pacer);
            setBurnedRare(burnedBreakdown.rare);
            setBurnedEpic(burnedBreakdown.epic);
            setBurnedLegendary(burnedBreakdown.legendary);
            setBurnedFounding(burnedBreakdown.founding);
            setTotalPegaBurned(burnedBreakdown.pacer +
                burnedBreakdown.rare + burnedBreakdown.epic +
                burnedBreakdown.legendary + burnedBreakdown.founding
            );
        }

        setLoadingBurnedBreakdown(false)
    }

    async function getSpentDataFromAPI() {
        setLoadingSpentData(true)

        const spentData = await getSpentData();

        if (spentData) {
            setTotalVISBurned(spentData.visBurned || 0)
            setTotalPGXSpent(spentData.pgxSpent || 0)
        }

        setLoadingSpentData(false)
    }

    async function getSpentBreedDataFromAPI() {
        setLoadingSpentBreedData(true)

        const spentData = await getSpentBreedData();

        if (spentData) {
            setTotalVISBurnedBreed(spentData.visBurned || 0)
            setTotalPGXSpentBreed(spentData.pgxSpent || 0)
        }

        setLoadingSpentBreedData(false)
    }

    async function getPopulationDataFromAPI() {
        setLoadingPopulationData(true)

        const data = await getPopulationData();

        if (data) {
            setPopulationData(data)
        }

        setLoadingPopulationData(false)
    }

    useEffect(() => {
        getBurnedBreakdownFromAPI();
        getSpentDataFromAPI();
        getSpentBreedDataFromAPI();
        getPopulationDataFromAPI();
    }, [])

    return {
        totalVISBurned,
        totalVISBurnedBreed,
        totalPGXSpent,
        totalPGXSpentBreed,
        totalPegaBurned,
        populationData,
        burnedPacer,
        burnedRare,
        burnedEpic,
        burnedLegendary,
        burnedFounding,
        loadingBurnedBreakdown,
        loadingSpentData,
        loadingSpentBreedData,
        loadingPopulationData
    }
}
