import {useEffect, useState} from "react";
import axios from 'axios';

interface AggregatorApiGetResponse {
    data: {
        [key: string]: {
            decimals: number;
            name: string;
            price: number;
            symbol: string;
            type: string;
        }
    }
}

export default function useTokenLiveData () {
    const pgxAddress = "0xc1c93D475dc82Fe72DBC7074d55f5a734F8cEEAE".toLowerCase();
    const visAddress = "0xcC1B9517460D8aE86fe576f614d091fCa65a28Fc".toLowerCase();

    const [pgxPrice, setPgxPrice] = useState<number>(0);
    const [visPrice, setVisPrice] = useState<number>(0);

    const getCoinData = async (coinAdress: string): Promise<number> => {
        try {
            const { data }: AggregatorApiGetResponse = await axios.get(`https://aggregator-api.kyberswap.com/polygon/tokens?ids=${coinAdress}`);
            if (data.hasOwnProperty(coinAdress)) {
                return data[coinAdress].price;
            }
        } catch (err) {
            console.log(`error getting coin data for ${coinAdress}`)
        }
        return 0;
    }

    useEffect(() => {
        async function fetchCoinData() {
            const pgxPrice = await getCoinData(pgxAddress);
            const visPrice = await getCoinData(visAddress);

            setPgxPrice(pgxPrice);
            setVisPrice(visPrice);
        }
        fetchCoinData();

        const id = setInterval(() => {
            fetchCoinData();
        }, 60000)

        return () => clearInterval(id);
    }, [])

    return {
        pgxPrice,
        visPrice
    };
}
