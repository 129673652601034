import create, { StateCreator } from "zustand";
import { persist, PersistOptions } from "zustand/middleware";

import { Timeframe } from "../../interfaces/timeframe";

interface Preferences {
  pegaTableSize: 10 | 20 | 30 | 40 | 50;
  pegaTableView: "stats" | "regular";
  timeframe: Timeframe;
}

interface PreferenceState {
  preferences: Preferences;

  updatePreferences: (payload: Preferences) => void;
  clearPreferences: () => void;
}

// Relevant: using Typescript with persist
// https://github.com/pmndrs/zustand/issues/650
type PreferencePersist = (
  config: StateCreator<PreferenceState>,
  options: PersistOptions<PreferenceState>
) => StateCreator<PreferenceState>;

const initialPreferences: Preferences = {
  pegaTableSize: 20,
  pegaTableView: "regular",
  timeframe: Timeframe.Month,
};

export const usePreferenceStore = create<PreferenceState>(
  (persist as unknown as PreferencePersist)(
    (set) => ({
      preferences: initialPreferences,

      updatePreferences: (payload) => {
        set({ preferences: payload });
      },

      clearPreferences: () => {
        set({ preferences: initialPreferences });
      },
    }),
    {
      name: "apollo-preference-storage",
    }
  )
);
