import { FC } from "react";
import App from "./App";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "./theme";
import {
  SettingsProvider,
  SettingsConsumer,
} from "./contexts/settings-context";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

export const Root: FC = () => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <BrowserRouter>
        <SettingsProvider>
          <SettingsConsumer>
            {({ settings }) => (
              <ThemeProvider
                theme={createTheme({
                  direction: settings.direction,
                  responsiveFontSizes: settings.responsiveFontSizes,
                  mode: settings.theme,
                })}
              >
                <CssBaseline />
                <Toaster position="top-center" />
                <App />
              </ThemeProvider>
            )}
          </SettingsConsumer>
        </SettingsProvider>
      </BrowserRouter>
    </Web3ReactProvider>
  );
};
