import { useEffect} from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

export default function usePageTracking() {
    const location = useLocation();

    useEffect(() => {
        ReactGA.initialize("UA-38219102-5");
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);
};
