import {toast} from "react-hot-toast";

export async function copyTextToClipboard(text?: string | null) {
    if (!text) return;

    if ('clipboard' in navigator) {
        await navigator.clipboard.writeText(text);
    } else {
        document.execCommand('copy', true, text);
    }
    return toast.success('Copied to Clipboard!')
}
