import {useState} from "react";
import useInterval from "./useInterval";
import moment from "moment";

export default function useLiveClockUTC () {
    const [liveClock, setLiveClock] = useState<string>('');

    useInterval(
        () => {
            setLiveClock(moment().utc(false).format("LLL"))
        },
        1000
    )

    return liveClock;
}
