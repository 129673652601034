import Web3 from "web3";
import { AbiItem } from "web3-utils";

export const visAddress = "0xcC1B9517460D8aE86fe576f614d091fCa65a28Fc";
export const nullAddress = "0x000000000000000000000000000000000000dEaD"; // null address to burn VIS

const transferABI = [
  {
    constant: false,
    inputs: [
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
];

const provider = Web3.givenProvider;

export const Web3js = new Web3(provider);

export const contract = new Web3js.eth.Contract(
  transferABI as AbiItem[],
  visAddress
);
