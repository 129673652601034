import React, {
    FC,
    useEffect,
    useState,
} from "react";
import { Helmet } from "react-helmet";
import {
    Box,
    Container,
    Grid,
    Typography,
    Card,
    TextField,
    useMediaQuery,
    Theme,
    MenuItem,
} from "@mui/material";
import { useFetch } from "use-http";
import { useTheme } from "@mui/material/styles";
import { Timeframe } from "../../interfaces/timeframe";
import { PegaTotalBurnedCard } from "../../components/metaverse/overview/PegaSalesSummary/PegaTotalBurnedCard";
import { PegaPopulationBurnedChart, PegaBredSummaryData } from "../../components/metaverse/pega-fuse";
import { timeframeToEpochSeconds } from "../../utils/timeframeToDate";
import moment from 'moment';
import useLiveClockUTC from "../../hooks/useLiveClockUTC";
import { usePreferenceStore } from "../../stores/preferences";
import shallow from "zustand/shallow";

const PegaFusingPage: FC = () => {
    const theme = useTheme();
    const { preferences, updatePreferences } = usePreferenceStore(
        ({ preferences, updatePreferences }) => ({
            preferences,
            updatePreferences,
        }),
        shallow
    );
    const liveClockUTC = useLiveClockUTC();

    const [totalBurned, setTotalBurned] = useState<number>(0);
    const [totalBurnedData, setTotalBurnedData] = useState<any>({ dataset: [], total: 0 });

    const [totalBurnedLabels, setTotalBurnedLabels] = useState<any>(null);
    const [populatingSummaryData, setPopulatingSummaryData] = useState<boolean>(false);

    const { get: getBurnData } = useFetch(`${process.env.REACT_APP_BASE_API_URL}/assets/burn`, {
        loading: true,
        retries: 5,
        cacheLife: 1 * 60000, // 1 minutes
        persist: false
    });

    const populateSummaryData = async () => {
        setPopulatingSummaryData(true);
        const burnData = await getBurnData(`/historical${timeframeToEpochSeconds(preferences.timeframe) ? '?since=' + timeframeToEpochSeconds(preferences.timeframe) : ''}`);
        const totalBurnData = await getBurnData('/');

        setTotalBurned(totalBurnData.pegaBurned);

        let burnedLabelsArr: Array<any> = [];

        let totalBurnedDataset = [];
        let totalBurnedSumValue = burnData?.reduce((pb: number, c: any) => pb + c.pegaBurned, 0);

        for (let i = 0; i < burnData.length; i++) {
            burnedLabelsArr.push(moment.unix(burnData[i].epoch).format('DD-MMM-YY'));
            totalBurnedDataset.push(burnData[i].pegaBurned);
        }

        const uniqueBurnedLabels = new Set(burnedLabelsArr);
        // @ts-ignore
        setTotalBurnedLabels([...uniqueBurnedLabels]);

        setTotalBurnedData({
            dataset: [{
                name: 'Burned Pega',
                data: totalBurnedDataset,
            }],
            total: totalBurnedSumValue,
        });

        setPopulatingSummaryData(false);
    };

    const isSm = useMediaQuery(
        (theme: Theme) => theme.breakpoints.down('sm'),
        {
            noSsr: true
        },
    );

    useEffect(() => {
        populateSummaryData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preferences.timeframe]);
    return (
        <>
            <Helmet>
                <title>Pega Fusing | Pegaxy Apollo</title>
            </Helmet>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 6
                }}
            >
                <Container maxWidth="xl">
                    <Box sx={{ mb: 4 }}>
                        <Grid
                            container
                            justifyContent="space-between"
                            spacing={3}
                            sx={{ px: 2, mb: 3 }}
                        >
                            <Grid item>
                                <Typography variant="h4">
                                    Fusing
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    m: -1
                                }}
                            >
                                {
                                    !isSm && (
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                color: theme.palette.text.primary

                                            }}
                                        >
                                            {`(UTC - ${liveClockUTC})`}
                                        </Typography>
                                    )
                                }
                                <TextField
                                    value={preferences.timeframe}
                                    label="Period"
                                    select
                                    size="small"
                                    sx={{ m: 1, minWidth: 200 }}
                                    onChange={(e) => {
                                        const timeframe: Timeframe = e.target.value as Timeframe;
                                        // setTimeframe(timeframe)
                                        updatePreferences({
                                            ...preferences,
                                            timeframe: timeframe,
                                        });
                                    }}
                                >
                                    <MenuItem value="today">
                                        Today
                                    </MenuItem>
                                    <MenuItem value="yesterday">
                                        Today + Yesterday
                                    </MenuItem>
                                    <MenuItem value="week">
                                        Last 7 days
                                    </MenuItem>
                                    <MenuItem value="biweek">
                                        Last 15 days
                                    </MenuItem>
                                    <MenuItem value="month">
                                        Last month
                                    </MenuItem>
                                    <MenuItem value="all">
                                        All
                                    </MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sx={{ mx: 2 }}>
                                <Grid container spacing={1}>
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <Card sx={{ boxShadow: 10, p: 2, pb: 0 }}>
                                            <PegaTotalBurnedCard
                                                data={totalBurnedData}
                                                labels={totalBurnedLabels} totalBurned={totalBurned} loading={populatingSummaryData}
                                            />
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <PegaBredSummaryData />
                            </Grid>
                            <Grid item xs={12}>
                                <PegaPopulationBurnedChart />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default PegaFusingPage;