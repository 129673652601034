import moment from "moment";
import {
    PegaBloodLine,
    PegaBloodLineBreedCooldownMillis
} from "../interfaces/pega";
import { PegaTableData } from "../components/personal/pega/PegaTable/PegaTable";

export const calculateBreedCooldown = (pega: PegaTableData) => {
    const nowMoment = moment();
    const canBreedAtMoment = moment.unix(pega.canBreedAt);
    const timeNeededMillis = moment.duration(canBreedAtMoment.diff(nowMoment)).as('millisecond');

    return Math.max(0, timeNeededMillis)
}

export const calculateRaceCooldown = (pega: PegaTableData) => {
    const nowMoment = moment();
    const canRaceAtMoment = moment.unix(pega.canRaceAt);
    const timeNeededMillis = moment.duration(canRaceAtMoment.diff(nowMoment)).as('millisecond');

    return Math.max(0, timeNeededMillis)
}
