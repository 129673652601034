import PGX from "../../../assets/PGXIcon.png";

export const PgxIcon = () => {
    return (
        <div style={{
            width: 30,
            height: 30,
            display: 'inline-block'
        }}>
            <img style={{ maxWidth: '100%' }} src={PGX} alt=""/>
        </div>
    )
}
