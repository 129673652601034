export const processErrorMessage = (error?: any) => {
  // Validation Errors

  const responseMsg = error?.response?.data?.error?.details?.[0]?.message;

  if (responseMsg) {
    return responseMsg;
  }

  const responseData = error?.response?.data;

  if (responseData && typeof responseData === 'string') {
    return responseData;
  }

  const responseDataError = error?.response?.data?.error;
  if (responseDataError && typeof responseDataError === 'string') {
    return responseDataError;
  }

  // Other Types
  if (error?.message) return error.message;

  return JSON.stringify(error);
};
