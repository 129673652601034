import { FC, useEffect, useState } from "react";
import axios from "axios";
import shallow from "zustand/shallow";

import { LocalFireDepartment } from "@mui/icons-material";
import {
  Alert,
  Box,
  Grid,
  Link,
  TextField,
  Divider,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Web3 from "web3";
import { TransactionReceipt } from "web3-core";

import { useAuthStore } from "../../../../stores/auth";
import useTokenBalances, {
  TokenBalance,
} from "../../../../hooks/useTokenBalances";
import useWeb3Auth from "../../../../hooks/useWeb3Auth";
import { Window } from "../../../../interfaces/window";
import { abbreviateId } from "../../../../utils/humanizer";
import {
  contract,
  nullAddress,
  visAddress,
  Web3js,
} from "../../../../web3/transfer-contract";
import { LoadingButton } from "@mui/lab";

const TokenBurnCard: FC = () => {
  const { metamaskAccount } = useWeb3Auth();
  const { user, storedToken } = useAuthStore(
    ({ user, storedToken }) => ({
      user,
      storedToken,
    }),
    shallow
  );

  const { balances, loading, getAllBalances } = useTokenBalances();

  const [visBalance, setVisBalance] = useState<TokenBalance | undefined>({});
  const [amount, setAmount] = useState<string>("");
  const [txHash, setTxHash] = useState<string | null>(null);
  const [txStatus, setTxStatus] = useState<TransactionReceipt | null>(null);

  useEffect(() => {
    setVisBalance(balances.find((balance) => balance.address === visAddress));
  }, [balances]);

  useEffect(() => {
    if (!txHash || txStatus) return;

    const checkTransactionStatus = setInterval(async () => {
      const receipt = await Web3js.eth.getTransactionReceipt(txHash);
      const { status } = receipt || {};

      if (status === null) return;

      if (receipt.status) {
        setAmount("");
        getAllBalances();
      }

      setTxStatus(receipt);

      if (receipt.status === true) {
        await axios.post(
          `${process.env.REACT_APP_BASE_API_URL}/assets/burn/add`,
          {
            txID: txHash,
          },
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
      }
    }, 3000);

    return () => clearInterval(checkTransactionStatus);
  }, [getAllBalances, storedToken, txHash, txStatus]);

  const handleChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value);
  };

  const setAmountToBalance = () => {
    if (visBalance?.value)
      setAmount((Number(visBalance?.value) - 0.0045).toFixed(2));
  };

  const getAddress = () => {
    const nextAddress = user?.publicAddress || metamaskAccount;

    if (!nextAddress) return "";

    return abbreviateId(nextAddress);
  };

  const handleBurn = async () => {
    const { ethereum } = window as Window;

    const nextAmount = Web3.utils.toWei(amount, "ether");

    const getData = contract.methods
      .transfer(nullAddress, nextAmount)
      .encodeABI();

    // https://docs.metamask.io/guide/sending-transactions.html
    const transactionParameters = {
      nonce: "0x00", // ignored by MetaMask
      to: visAddress, // Required except during contract publications.
      from: user?.publicAddress || metamaskAccount, // must match user's active address.
      data: getData,
      value: "0x00", // Only required to send ether to the recipient from the initiating external account.
    };

    const txHash = await ethereum?.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });

    if (typeof txHash === "string") {
      setTxHash(txHash);
      // Reset txStatus
      setTxStatus(null);
    }
  };

  const isUserConnected = !!user?.publicAddress || !!metamaskAccount;

  const isAmountInvalid =
    !!amount &&
    (!visBalance?.value || parseFloat(amount) > parseFloat(visBalance?.value));

  const emptyValue = !amount || !parseFloat(amount);

  const isBurningPending = !!(txHash && !txStatus);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          px: 3,
          py: 1,
        }}
      >
        <Typography
          color="text.secondary"
          variant="caption"
          fontWeight="bold"
          sx={{ mr: 1 }}
        >
          Current address
        </Typography>
        <Typography variant="caption" fontWeight="bold">
          {getAddress()}
        </Typography>
      </Box>
      <Divider flexItem />
      <Grid
        container
        spacing={1}
        alignItems="flex-end"
        sx={{ px: 3, mt: 2, mb: 3 }}
      >
        <Grid item xs={12}>
          <Box sx={{ mb: 0.5, display: "flex", alignItems: "flex-end" }}>
            <Typography
              variant="caption"
              color="text.secondary"
              fontWeight="bold"
              width="100%"
            >
              AMOUNT TO BURN
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              fontWeight="bold"
              sx={{ fontWeight: "bold", mr: 1 }}
            >
              BALANCE
            </Typography>
          </Box>

          <TextField
            fullWidth
            id="amount"
            name="amount"
            value={amount}
            onChange={handleChangeAmount}
            placeholder="Amount"
            type="tel"
            sx={{
              mb: 1,
              boxShadow: 8,
              borderRadius: 1,
              backgroundColor: "background.default",
            }}
            InputProps={{
              sx: { fontSize: "14px" },
              endAdornment: (
                <div style={{ display: "inline-flex", marginLeft: 10 }}>
                  <Typography
                    variant="caption"
                    color="primary"
                    fontWeight="bold"
                    sx={{ fontWeight: "bold", cursor: "pointer" }}
                    onClick={setAmountToBalance}
                  >
                    MAX
                  </Typography>
                  <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                  {!loading ? (
                    <>
                      <Typography
                        variant="caption"
                        sx={{ fontWeight: "bold", mr: 1 }}
                      >
                        {visBalance?.value
                          ? (Number(visBalance?.value) - 0.0045).toFixed(2)
                          : "0"}
                      </Typography>
                      <Box sx={{ width: "20px", height: "20px" }}>
                        {visBalance?.icon}
                      </Box>
                    </>
                  ) : (
                    <CircularProgress size={20} />
                  )}
                </div>
              ),
            }}
            disabled={!visBalance || !isUserConnected || isBurningPending}
            error={isAmountInvalid}
            helperText={
              isAmountInvalid &&
              "Amount is more than the VIS balance in your wallet"
            }
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            fullWidth
            variant="contained"
            startIcon={<LocalFireDepartment />}
            onClick={handleBurn}
            size="large"
            disabled={isAmountInvalid || emptyValue}
            sx={{ mt: 1 }}
            loading={isBurningPending}
          >
            Burn
          </LoadingButton>
          <Typography variant="caption" color="text.secondary">
            * Token burn is permanent - this action cannot be reverted
          </Typography>
        </Grid>
      </Grid>

      {(txHash || txStatus) && (
        <Box sx={{ width: "100%", mt: 2 }}>
          {isBurningPending && (
            <Alert
              iconMapping={{
                success: <CircularProgress size={21} />,
              }}
            >
              Transaction is Pending... (View{" "}
              <Link
                href={`https://polygonscan.com/tx/${txHash}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                Transaction
              </Link>
              )
            </Alert>
          )}

          {txStatus?.status === true && (
            <Alert severity="success">
              You have successfully burnt VIS (View{" "}
              <Link
                href={`https://polygonscan.com/tx/${txStatus.transactionHash}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                Transaction
              </Link>
              )
            </Alert>
          )}

          {txStatus?.status === false && (
            <Alert severity="error">
              Transaction Failed (View{" "}
              <Link
                href={`https://polygonscan.com/tx/${txStatus?.transactionHash}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                Transaction
              </Link>
              ). Please try again.
            </Alert>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TokenBurnCard;
