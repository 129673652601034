import React, {
    FC,
    useEffect,
    useState,
} from "react";
import { Helmet } from "react-helmet";
import {
    Box,
    Container,
    Grid,
    Typography,
    Card,
    TextField,
    useMediaQuery,
    Theme,
    MenuItem,
} from "@mui/material";
import { useFetch } from "use-http";
import { useTheme } from "@mui/material/styles";
import { Timeframe } from "../../interfaces/timeframe";
import { PegaFusedSummaryData, PegaTotalPopulationBredCard } from "../../components/metaverse/pega-fuse";
import { timeframeToEpochSeconds } from "../../utils/timeframeToDate";
import moment from 'moment';
import useLiveClockUTC from "../../hooks/useLiveClockUTC";
import { usePreferenceStore } from "../../stores/preferences";
import shallow from "zustand/shallow";

const PegaBreedingPage: FC = () => {
    const theme = useTheme();
    const { preferences, updatePreferences } = usePreferenceStore(
        ({ preferences, updatePreferences }) => ({
            preferences,
            updatePreferences,
        }),
        shallow
    );
    const liveClockUTC = useLiveClockUTC();

    const [totalPega, setTotalPega] = useState<number>(0);
    const [totalBredPega, setTotalBredPega] = useState<number>(0);
    const [totalBredPegaData, setTotalBredPegaData] = useState<any>({ dataset: [], total: 0 });
    const [totalBredPegaDataLabels, setTotalBredPegaDataLabels] = useState<any>(null);

    const [populatingSummaryData, setPopulatingSummaryData] = useState<boolean>(false);

    const { get: getAssetsData } = useFetch(`${process.env.REACT_APP_BASE_API_URL}/assets/count`, {
        loading: true,
        retries: 5,
        cacheLife: 1 * 60000, // 1 minutes
        persist: false
    });

    const { get: getBurnData } = useFetch(`${process.env.REACT_APP_BASE_API_URL}/assets/burn`, {
        loading: true,
        retries: 5,
        cacheLife: 1 * 60000, // 1 minutes
        persist: false
    });

    const differenceProcessor = (totalAssets: Array<any>, totalBurned: Array<any>): Array<number> => (
        totalAssets.map(data => {
            const foundIndex = totalBurned.findIndex(d => d.timestamp === data.timestamp)
            if (foundIndex > -1) {
                const sum = data.pega - (totalBurned[foundIndex].pegaBurned / 2);
                return sum < 0 ? 0 : sum;
            }
            return data.pega;
        })
    );

    const populateSummaryData = async () => {
        setPopulatingSummaryData(true);
        const assetsData = await getAssetsData(`/historical${timeframeToEpochSeconds(preferences.timeframe) ? '?since=' + timeframeToEpochSeconds(preferences.timeframe) : ''}`)
        const burnData = await getBurnData(`/historical${timeframeToEpochSeconds(preferences.timeframe) ? '?since=' + timeframeToEpochSeconds(preferences.timeframe) : ''}`)
        const totalAssetsData = await getAssetsData('/');

        setTotalPega(totalAssetsData.pega);

        let assetsLabelsArr: Array<any> = [];
        let totalPopulationValue = assetsData?.reduce((p: number, c: any) => p + c.pega, 0);
        let totalBurnedSumValue = burnData?.reduce((pb: number, c: any) => pb + c.pegaBurned, 0);


        for (let i = 0; i < assetsData.length; i++) {
            assetsLabelsArr.push(moment.unix(assetsData[i].epoch).format('DD-MMM-YY'));
        }

        const uniqueAssetsLabels = new Set(assetsLabelsArr);
        // @ts-ignore
        setTotalBredPegaDataLabels([...uniqueAssetsLabels]);

        setTotalBredPega(totalPopulationValue - (totalBurnedSumValue / 2));

        setTotalBredPegaData({
            dataset: [{
                name: 'Bred Pega',
                data: differenceProcessor(assetsData, burnData),
            }],
            total: totalPopulationValue - (totalBurnedSumValue / 2),
        });

        setPopulatingSummaryData(false);
    };

    const isSm = useMediaQuery(
        (theme: Theme) => theme.breakpoints.down('sm'),
        {
            noSsr: true
        },
    );

    useEffect(() => {
        populateSummaryData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preferences.timeframe]);

    return (
        <>
            <Helmet>
                <title>Pega Breeding | Pegaxy Apollo</title>
            </Helmet>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 6,
                }}
            >
                <Container maxWidth="xl">
                    <Box sx={{ mb: 4 }}>
                        <Grid
                            container
                            justifyContent="space-between"
                            spacing={3}
                            sx={{ px: 2, mb: 3 }}
                        >
                            <Grid item>
                                <Typography variant="h4">
                                    Breeding
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    m: -1
                                }}
                            >
                                {
                                    !isSm && (
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                color: theme.palette.text.primary

                                            }}
                                        >
                                            {`(UTC - ${liveClockUTC})`}
                                        </Typography>
                                    )
                                }
                                <TextField
                                    value={preferences.timeframe}
                                    label="Period"
                                    select
                                    size="small"
                                    sx={{ m: 1, minWidth: 200 }}
                                    onChange={(e) => {
                                        const timeframe: Timeframe = e.target.value as Timeframe;
                                        // setTimeframe(timeframe)
                                        updatePreferences({
                                            ...preferences,
                                            timeframe: timeframe,
                                        });
                                    }}
                                >
                                    <MenuItem value="today">
                                        Today
                                    </MenuItem>
                                    <MenuItem value="yesterday">
                                        Today + Yesterday
                                    </MenuItem>
                                    <MenuItem value="week">
                                        Last 7 days
                                    </MenuItem>
                                    <MenuItem value="biweek">
                                        Last 15 days
                                    </MenuItem>
                                    <MenuItem value="month">
                                        Last month
                                    </MenuItem>
                                    <MenuItem value="all">
                                        All
                                    </MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sx={{ mx: 2 }}>
                                <Grid container spacing={1}>
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <Card sx={{ boxShadow: 10, p: 2, pb: 0 }}>
                                            <PegaTotalPopulationBredCard
                                                data={totalBredPegaData}
                                                labels={totalBredPegaDataLabels}
                                                totalPega={totalPega}
                                                loading={populatingSummaryData}
                                            />
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <PegaFusedSummaryData
                                    showTypes={false}
                                    totalBredPega={totalBredPega}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default PegaBreedingPage;