import { FC } from "react";

import { Box, Card, Typography } from "@mui/material";

import { ConnectButton } from "../../metamask-connect-btn";
import useWeb3Auth from "../../../../hooks/useWeb3Auth";
import { injected } from "../../../../web3/connectors";

const ConnectCard: FC = () => {
  const { setActivatingConnector, activate } = useWeb3Auth();

  const handleConnectClick = () => {
    setActivatingConnector(injected);
    activate(injected);
  };

  return (
    <Card
      sx={{
        px: 3,
        py: 5,
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h5"
        sx={{ display: "inline-block", textAlign: "center" }}
      >
        You need to connect your Polygon account to access the Token Burn App
      </Typography>
      <Box sx={{ mt: 4 }}>
        <ConnectButton onConnect={() => handleConnectClick()} />
      </Box>
    </Card>
  );
};

export default ConnectCard;
