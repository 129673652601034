import {useWeb3React} from "@web3-react/core";
import {useEffect, useState} from "react";
import {injected} from "./connectors";
import * as Sentry from "@sentry/browser";

export function useEagerConnect() {
    const { activate, active } = useWeb3React()

    const [tried, setTried] = useState(false)

    useEffect(() => {
        injected.isAuthorized().then((isAuthorized: boolean) => {
            if (isAuthorized) {
                activate(injected, undefined, true).catch(() => {
                    setTried(true)
                })
            } else {
                setTried(true)
            }
        })
    }, []) // intentionally only running on mount (make sure it's only mounted once :))

    // if the connection worked, wait until we get confirmation of that to flip the flag
    useEffect(() => {
        if (!tried && active) {
            setTried(true)
        }
    }, [tried, active])

    return tried
}

export function useInactiveListener(suppress: boolean = false) {
    const { active, error, activate, account } = useWeb3React()

    useEffect((): any => {
        const { ethereum } = window as any
        if (ethereum && ethereum.on && !active && !error && !suppress) {
            const handleConnect = () => {
                if (account) {
                    Sentry.setUser({ id: account });
                }
                activate(injected)
            }
            const handleChainChanged = (chainId: string | number) => {
                activate(injected)
            }
            const handleAccountsChanged = (accounts: string[]) => {
                if (accounts.length > 0) {
                    if (account) {
                        Sentry.setUser({ id: account });
                    }
                    activate(injected)
                }
            }
            const handleNetworkChanged = (networkId: string | number) => {
                activate(injected)
            }

            ethereum.on('connect', handleConnect)
            ethereum.on('chainChanged', handleChainChanged)
            ethereum.on('accountsChanged', handleAccountsChanged)
            ethereum.on('networkChanged', handleNetworkChanged)

            return () => {
                if (ethereum.removeListener) {
                    ethereum.removeListener('connect', handleConnect)
                    ethereum.removeListener('chainChanged', handleChainChanged)
                    ethereum.removeListener('accountsChanged', handleAccountsChanged)
                    ethereum.removeListener('networkChanged', handleNetworkChanged)
                }
            }
        }
    }, [active, error, suppress, activate])
}
