import moment from 'moment';
import {Timeframe} from '../interfaces/timeframe';

export const timeframeToDate = (timeframe: Timeframe | undefined) => {
    switch (timeframe) {
        case Timeframe.All:
            return '';
        case Timeframe.Month:
            return moment().utc().subtract(1, 'month').startOf('day').format();
        case Timeframe.Biweek:
            return moment().utc().subtract(15, 'day').startOf('day').format();
        case Timeframe.Week:
            return moment().utc().subtract(7, 'day').startOf('day').format();
        case Timeframe.Yesterday:
            return moment().utc().subtract(1, 'day').startOf('day').format();
        case Timeframe.Today:
            return moment().utc().startOf('day').format();
        default:
            return '';

    }
}

export const timeframeToEpochSeconds = (timeframe: Timeframe | undefined) => {
    switch (timeframe) {
        case Timeframe.All:
            return null;
        case Timeframe.Month:
            return moment().utc().subtract(1, 'month').startOf('day').unix();
        case Timeframe.Biweek:
            return moment().utc().subtract(15, 'day').startOf('day').unix();
        case Timeframe.Week:
            return moment().utc().subtract(7, 'day').startOf('day').unix();
        case Timeframe.Yesterday:
            return moment().utc().subtract(1, 'day').startOf('day').unix();
        case Timeframe.Today:
            return moment().utc().startOf('day').unix();
        default:
            return '';

    }
}

export const timeframeToDaysPassed = (timeframe: Timeframe | undefined) => {
    switch (timeframe) {
        case Timeframe.All:
            return moment.duration({ from: moment(new Date(2021, 10, 30)).startOf('day').valueOf(), to: moment().startOf('day').valueOf() }).asDays();
        case Timeframe.Month:
            return moment.duration({ from: moment().subtract(1, 'month').startOf('day').valueOf(), to: moment().startOf('day').valueOf() }).asDays();
        case Timeframe.Biweek:
            return moment.duration({ from: moment().subtract(15, 'day').startOf('day').valueOf(), to: moment().startOf('day').valueOf() }).asDays();
        case Timeframe.Week:
            return moment.duration({ from: moment().subtract(7, 'day').startOf('day').valueOf(), to: moment().startOf('day').valueOf() }).asDays();
        case Timeframe.Yesterday:
            return moment.duration({ from: moment().subtract(1, 'day').startOf('day').valueOf(), to: moment().startOf('day').valueOf() }).asDays();
        case Timeframe.Today:
            return 0;
        default:
            return '';
    }
}
