import { FC, useState } from "react";
import {
  Typography,
  Box,
  Modal,
  Fade,
  Paper,
  IconButton,
  Button,
} from "@mui/material";
import { Close, LocalFireDepartment } from "@mui/icons-material";
import { toast } from "react-hot-toast";
import Web3 from "web3";

import { getNonce, verifyAddress } from "../../../api/auth";

import useWeb3Auth from "../../../hooks/useWeb3Auth";
import ConnectCard from "./components/ConnectCard";
import TokenBurnCard from "./components/TokenBurnCard";
import shallow from "zustand/shallow";
import { useAuthStore } from "../../../stores/auth";

export const BurnModal: FC = () => {
  const { metamaskAccount, connector } = useWeb3Auth();

  const { user, getUser } = useAuthStore(
    ({ user, getUser }) => ({
      user,
      getUser,
    }),
    shallow
  );

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const startAccountVerification = async () => {
    const data = await getNonce(metamaskAccount as string);

    if (!data.nonce)
      return toast.error("Unable to verify account, please try again later.");

    const provider = await connector?.getProvider();
    const web3 = new Web3(provider as any);

    try {
      const signature = await web3.eth.personal.sign(
        `Welcome to Pegaxy Apollo | ${data.nonce}`,
        metamaskAccount as string,
        ""
      );

      const tokenData = await verifyAddress(
        metamaskAccount as string,
        signature
      );

      if (!tokenData.accessToken)
        return toast.error("Unable to verify account, please try again later.");

      getUser(tokenData.accessToken);

      toast.success("Account verified!");
    } catch (err) {
      return toast.error("Unable to verify account, please try again later.");
    }
  };

  return (
    <>
      <Box sx={{ mt: 2, px: 2 }}>
        <Button
          size="small"
          variant="outlined"
          color="warning"
          onClick={() => setModalOpen(true)}
          startIcon={<LocalFireDepartment />}
          fullWidth
        >
          Token Burn
        </Button>
      </Box>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        closeAfterTransition
        BackdropProps={{
          timeout: 100,
        }}
      >
        <Fade in={modalOpen}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignItems: { xs: "center", md: "baseline" },
              justifyContent: "center",
              padding: { xs: 0, md: 4 },
              marginTop: { xs: 0, md: 14 },
              m: 3,
            }}
          >
            <Paper
              elevation={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                margin: { xs: 0, md: 3 },
                minHeight: 300,
                maxHeight: "100%",
                outline: "none",
                width: "100%",
                maxWidth: 440,
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  p: 3,
                  borderBottom: 1,
                  borderColor: "divider",
                }}
              >
                <Typography variant="h6">Token Burn</Typography>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton onClick={() => setModalOpen(false)}>
                  <Close fontSize="small" />
                </IconButton>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  overflow: "auto",
                }}
              >
                {metamaskAccount ? (
                  user ? (
                    <TokenBurnCard />
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        height: "100%",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{ p: 5 }}
                      >
                        Your account needs to be verified before using the Token
                        Burn DApp
                      </Typography>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => startAccountVerification()}
                      >
                        Verify Account
                      </Button>
                    </Box>
                  )
                ) : (
                  <ConnectCard />
                )}
              </Box>
            </Paper>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
