import React, {FC, useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import {Box, Card, Grid, Tooltip, Typography} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {ValentineTicketFloorPriceCard, ValentineTicketFloorPriceData} from "./ValentineTicketFloorPriceCard";
import {useFetch} from "use-http";

enum TicketRarity {
    Legendary = "Legendary",
    Epic = "Epic",
    Rare = "Rare"
}

export const ValentineTicketFloorPrices: FC = () => {
    const theme = useTheme();

    const [legendaryFloorData, setLegendaryFloorData] = useState<ValentineTicketFloorPriceData>();
    const [epicFloorData, setEpicFloorData] = useState<ValentineTicketFloorPriceData>();
    const [rareFloorData, setRareFloorData] = useState<ValentineTicketFloorPriceData>();

    const {
        get: getPricesData,
        loading: loadingPricesData,
        error: errorEarningsData
    } = useFetch(`${process.env.REACT_APP_BASE_API_URL}/tickets/valentine/prices`, {
        loading: true,
        retries: 5,
        cacheLife: 2 * 60000, // 2 minutes
        persist: true
    });

    const getData = async () => {
        const data = await getPricesData(`/floor`)

        console.log('data', data);

        setLegendaryFloorData(data.find((i: ValentineTicketFloorPriceData) => i.ticketRarity === TicketRarity.Legendary))
        setEpicFloorData(data.find((i: ValentineTicketFloorPriceData) => i.ticketRarity === TicketRarity.Epic))
        setRareFloorData(data.find((i: ValentineTicketFloorPriceData) => i.ticketRarity === TicketRarity.Rare))
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    px: 2,
                    pb: 1
                }}
            >
                <Card
                    sx={{
                        p: 3,
                        boxShadow: 10
                    }}
                >
                    <Grid
                        container

                        sx={{
                            ml: 'auto',
                            justifyContent: "space-between",
                            flexDirection: "column",
                            alignItems: "center"
                        }}
                    >
                        <Grid
                            item
                            sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Typography
                                color="textPrimary"
                                variant="h6"
                                sx={{
                                    textAlign: "center"
                                }}
                            >
                               Ticket Floor Prices
                            </Typography>
                            <Tooltip
                                title={"Data is updated every minute. However, Pegas may already be bought when the link to the marketplace is clicked, resulting in a blank marketplace page."}
                                placement={"right"}>
                                <InfoOutlinedIcon sx={{ml: 1}}/>
                            </Tooltip>
                        </Grid>

                    </Grid>
                </Card>
            </Box>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    pt: 0.25,
                    pb: 1,
                    px: 2
                }}
            >
                <Grid
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        lg={4}
                        md={4}
                        xs={12}
                    >
                        <ValentineTicketFloorPriceCard
                            loading={loadingPricesData}
                            floorPriceData={legendaryFloorData}
                            ticketTitle={"Legendary"}
                            ticketMarketplaceLink={`https://marketplace.pegaxy.io/items/${legendaryFloorData?.ticketIds?.length ? legendaryFloorData?.ticketIds[0] : '#'}`}
                            ticketBackgroundColor={""}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={4}
                        md={4}
                        xs={12}
                    >
                        <ValentineTicketFloorPriceCard
                            loading={loadingPricesData}
                            floorPriceData={epicFloorData}
                            ticketTitle={"Epic"}
                            ticketMarketplaceLink={`https://marketplace.pegaxy.io/items/${epicFloorData?.ticketIds?.length ? epicFloorData?.ticketIds[0] : '#'}`}
                            ticketBackgroundColor={""}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={4}
                        md={4}
                        xs={12}
                    >
                        <ValentineTicketFloorPriceCard
                            loading={loadingPricesData}
                            floorPriceData={rareFloorData}
                            ticketTitle={"Rare"}
                            ticketMarketplaceLink={`https://marketplace.pegaxy.io/items/${rareFloorData?.ticketIds?.length ? rareFloorData?.ticketIds[0] : '#'}`}
                            ticketBackgroundColor={""}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
